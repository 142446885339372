import queryString from 'query-string';
import http from '../../../../utils/http';


export default {
  getLocales: async ({ commit }) => {
    try {
      const response = await http.v2.get('/support/article/locales');
      const { data } = response;

      commit('SET_LOCALES', data.locales);

      return data.locales;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getArticles: async ({ commit }, payload) => {
    const { filters } = payload;

    const query = queryString.stringify(filters);

    try {
      const response = await http.v2.get(`/support/articles?${query}`);
      const { data } = response;

      if (data.articles && data.articles.length) {
        data.articles = data.articles.filter(v => !v.draft);
      }

      commit('SET_ARTICLES', data);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getArticle: async ({ commit }, payload) => {
    const { id, locale } = payload;

    try {
      const response = await http.v2.get(`/support/articles/${id}?locale=${locale.toLowerCase()}`);
      const { data } = response;

      commit('SET_ARTICLE', data.article);
      commit('SET_AUTHOR', data.users && data.users[0]);

      return data.article;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getAuthor: async ({ commit }, payload) => {
    const { userId } = payload;

    try {
      const response = await http.v2.get(`/support/article/authors/${userId}`);
      const { data } = response;

      commit('SET_AUTHOR', data.user);

      return data.user;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getAuthors: async ({ commit }) => {
    try {
      const response = await http.v2.get('/support/article/authors');
      const { data } = response;

      commit('SET_AUTHORS', data.users);

      return data.users;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

};
