<template>
  <div>
    <p
      class="text-xs mb-3 break-words"
      :style="{lineHeight: '20px'}"
    >
      <b>{{ $t('upload_labels.supported_types') }}:</b>
      <span class="text-grey-dark">
        {{ allowedFileExtensions.toString().replace(/,/g, ', ') }}
      </span>
    </p>
    <el-upload
      ref="uploader"
      :class="cls"
      drag
      :disabled="loading"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :before-upload="beforeUpload"
      :http-request="onUploadRequest"
      :on-error="onUploadError"
      :on-success="onUploadSuccess"
      :on-exceed="handleExceed"
      auto-upload="false"
      multiple
      :limit="attachmentLimit"
      :accept="allowedMimeTypes.toString()"
    >
      <i class="el-icon-upload" />
      <div class="el-upload__text text-xs">
        {{ $t('actions.add_files') }}
      </div>
      <div
        slot="tip"
        class="el-upload__tip text-xs break-words"
        :style="{ lineHeight: '18px', color: '#ff9f1c'}"
      >
        {{ $t('support.raise_ticket.attachment_reminder') }}
      </div>
    </el-upload>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'SupportUploader',

  components: {

  },

  props: {
    cls: {
      type: String,
      default: '',
    },

    modalVisible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const mimeTypes = {
      csv: 'text/csv',
      txt: 'text/plain',
      json: 'application/json',
      pdf: 'application/pdf',
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ppt: 'application/vnd.ms-powerpoint',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      xls: 'application/vnd.ms-excel',
      zip: 'application/zip',
      svg: 'image/svg+xml',
      jpeg: 'image/jpeg',
      jpg: 'image/jpeg',
      png: 'image/png',
      gif: 'image/gif',
      webp: 'image/webp',
      mp3: 'audio/mpeg',
      mp4: 'video/mp4',
      mpeg: 'video/mpeg',
      mov: 'video/quicktime',
      wmv: 'video/x-ms-wmv',
      wav: 'audio/wav',
    };

    const file = Object.keys(mimeTypes).reduce((a, b) => {
      a.mimeTypes.push(mimeTypes[b]);
      a.extensions.push(`.${b}`);
      return a;
    }, { mimeTypes: [], extensions: [] });

    return {
      fileSizeLimitMB: 35,
      attachmentLimit: 6,
      // products: [],
      // requestTypes: [],
      allowedMimeTypes: file.mimeTypes,
      allowedFileExtensions: file.extensions,
      attachments: [],
      uploading: false,
    };
  },

  watch: {
    uploading() {
      this.$emit('set-uploading', this.uploading);
    },

    attachments(newData) {
      this.$emit('set-attachments', newData);
    },

    modalVisible(nw, old) {
      if (nw !== old) {
        this.attachments = [];
      }
    },
  },

  created() {
    this.attachments = [];
  },

  beforeDestroy() {
    this.attachments = [];
  },

  methods: {
    ...mapActions({
      uploadFile: 'tickets/uploadFile',
      deleteFile: 'tickets/deleteFile',
    }),

    async handleRemove(file) {
      const { name } = file;

      const att = this.attachments.find(v => v.filename === name);

      if (att) {
        const { token, name: attName } = att;

        this.attachments = this.attachments.filter(v => v.name !== attName);

        try {
          await this.deleteFile({ token });
        } catch (e) {
          // do nothing
        }
      }
    },
    handlePreview() {
      // console.log(file);
    },
    handleExceed() {
      this.$message.warning(this.$t('support.upload.limit', { limit: this.attachmentLimit }));
    },
    beforeRemove(file) {
      return this.$confirm(this.$t('confirmations.remove', { item: file.name }));
    },
    beforeUpload(file) {
      const isAllowedFileType = this.allowedMimeTypes.includes(file.type);
      const isLt35M = file.size / 1024 / 1024 < this.fileSizeLimitMB;


      if (!isAllowedFileType) {
        this.$message.error(`${this.$t('support.upload.file_should_be')}: ${this.allowedFileExtensions.toString()}`);
      }
      if (!isLt35M) {
        this.$message.error(`${this.$t('support.upload.file_should_not')} ${this.fileSizeLimitMB}MB`);
      }
      return isAllowedFileType && isLt35M;
    },

    async onUploadRequest(fileObj) {
      try {
        this.uploading = true;
        const { token } = await this.uploadFile({ file: fileObj.file, fileObj });
        if (token) {
          this.attachments.push({ token, filename: fileObj.file.name });
          fileObj.onSuccess();
          this.$notify.success(this.$t('success.uploaded', { value: fileObj.file.name }));
        } else {
          fileObj.onError();
        }
      } catch (e) {
        fileObj.onError();
      } finally {
        this.uploading = false;
      }
    },

    onUploadError(evt, file) {
      this.$notify.error(`${this.$t('support.upload.failed')}: ${file.name}`);
    },

    onUploadSuccess() {
      // this.$notify.success(`${file.name} has been successfuly uploaded.`);
    },
  },
};
</script>
