/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    user: {},
    loading: true,
    subaccounts: [],
    timeZoneName: null,
    timeZoneOffset: 0,
    timeZone: '(UTC-00)',
    isTimeZoneAllowed: false,
    zendeskUser: {},
    zendeskRequester: {},
    account: {},
  },

  getters: {
    getUser: st => st.user,
    getAccount: st => st.account,
    getZendeskUser: st => st.zendeskUser,
    getZendeskRequester: st => st.zendeskRequester,
    getSubaccounts: st => st.subaccounts,
    getTz: (st) => {
      const {
        timeZone,
        timeZoneName,
        timeZoneOffset,
      } = st;

      return {
        timeZone,
        timeZoneName,
        timeZoneOffset,
      };
    },
  },

  mutations: {
    SET_SUBACCOUNTS(state, payload) {
      state.subaccounts = payload;
    },

    SET_USER(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.user = payload.user;
    },

    SET_USER_ACCOUNT(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.account = payload;
    },

    SET_ZENDESK_USER(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.zendeskUser = payload;
    },

    SET_ZENDESK_REQUESTER(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.zendeskRequester = payload;
    },

    SET_TIMEZONE(st, payload) {
      st.auth = payload; // eslint-disable-line

      const {
        TimeZoneName,
        TimeZoneGMTOffset,
      } = payload;

      st.isTimeZoneAllowed = true;

      // User timezone settings
      st.timeZoneName = TimeZoneName || 'UTC / Greenwich Mean Time'; // eslint-disable-line
      st.timeZoneOffset = TimeZoneGMTOffset ? (TimeZoneGMTOffset / 60) : 0; // eslint-disable-line
      let timeZone = '(UTC-00)';
      if (TimeZoneGMTOffset) {
        const offset = new Date(TimeZoneGMTOffset * 1000).toUTCString().split(' ')[4].replace(/:00$/, '');
        let prefix = '-';
        if (TimeZoneGMTOffset > 0) {
          prefix = '+';
        }
        timeZone = `(UTC${prefix}${offset})`;
      }
      st.timeZone = timeZone; // eslint-disable-line
    },
  },

  actions,
};
