import queryString from 'query-string';
import fileSaver from 'file-saver';
import axios from 'axios';
import http from '../../../../utils/http';


export default {
  getProductTypes: async ({ commit }) => {
    try {
      const response = await http.v2.get('/support/product/types');
      const { data } = response;

      commit('SET_PRODUCT_TYPES', data.custom_field_options);

      return data.custom_field_options;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getRequestTypes: async ({ commit }) => {
    try {
      const response = await http.v2.get('/support/requests/types');
      const { data } = response;

      commit('SET_REQUEST_TYPES', data.custom_field_options);

      return data.custom_field_options;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  createRequest: async ({ commit }, payload) => {
    try {
      const response = await http.v2.post('/support/requests', {
        ...payload,
      });

      const { data } = response;
      return data.ticket;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  uploadFile: async ({ commit }, payload) => {
    const { file } = payload;
    const formData = new FormData();
    formData.append('file', file, file.name);
    const mimeType = file.type ? file.type : 'application/octet-stream';

    const q = encodeURI(`filename=${file.name}&mimetype=${mimeType}`);

    try {
      const response = await http.v2.request({
        method: 'post',
        data: formData,
        url: `/support/requests/attachments?${q}`,
        // onUploadProgress: () => {
        //   //  ProgressEvent.Loaded: Uploaded file size
        //   //  ProgressEvent.total: The total size of the uploaded file
        //   //  progress bar
        //   fileObj.onProgress();
        // },
      });

      const { data } = response;

      return data.upload;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  downloadFile: async ({ commit }, payload) => {
    const { file_name: filename, content_url: url } = payload;
    try {
      const response = await axios({
        url,
        method: 'GET',
        responseType: 'blob', // Important
      });

      fileSaver.saveAs(response.data, filename);

      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return { success: true };
      // return Promise.reject(err);
    }
  },

  deleteFile: async ({ commit }, payload) => {
    const { token } = payload;

    try {
      await http.v2.delete(`/support/requests/attachments/${token}`);
      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // eslint-disable-next-line no-unused-vars
  getRequests: async ({ commit }, payload = {}) => {
    const { filters } = payload;

    const query = queryString.stringify(filters);

    try {
      // const response = await http.v2.get('/support/requests');
      const response = await http.v2.get(`/support/requests?${query}`);
      const { data } = response;

      if (!filters.export) {
        commit('SET_REQUESTS', data);
      }

      return data.results;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getRequest: async ({ commit }, payload = {}) => {
    const { id } = payload;

    if (!id) return Promise.reject(new Error('Cancelled'));

    try {
      const response = await http.v2.get(`/support/requests/${id}`);
      const { data } = response;

      commit('SET_REQUEST', { ...data.ticket });

      return data.ticket;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getRequestComments: async ({ commit }, payload = {}) => {
    const { id } = payload;

    if (!id) return Promise.reject(new Error('Cancelled'));

    try {
      const response = await http.v2.get(`/support/requests/${id}/comments`);
      const { data } = response;

      commit('SET_REQUEST_COMMENTS', data);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  addRequestComment: async ({ commit }, payload = {}) => {
    // eslint-disable-next-line no-unused-vars
    const { id } = payload;

    try {
      await http.v2.put(`/support/requests/${id}/comments`, { ...payload });

      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  addRating: async ({ commit }, payload = {}) => {
    // eslint-disable-next-line no-unused-vars
    const { id, rating, comment: c } = payload;

    try {
      await http.v2.post(`/support/requests/${id}/rating`, { rating, comment: (c || '').trim() });

      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

};
