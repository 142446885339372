<template>
  <div class="mb-20">
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      class="mt-5"
    >
      <el-breadcrumb-item :key="1">
        <span
          class="text-blue cursor-pointer"
          @click="navigate({ path: '/'})"
        >
          {{ $t('support.main.support_overview.title') }}
        </span>
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :key="2"
        class="truncate"
      >
        <span
          :class="{ 'text-blue': $route.query.search_term}"
          class="text-grey cursor-pointer"
          @click="navigate({ path: '/articles'})"
        >{{ $t('support.articles.article_main_page') }}</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item
        v-if="$route.query.search_term"
        :key="3"
        class="truncate"
      >
        <span
          class="text-grey"
          @click="navigate({ path: '/articles', query: { ...$route.query }})"
        >{{ $t('support.articles.search_results') }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="w-3/5 mt-5">
      <h1 class="text-xl text-color font-semibold page-title mb-5">
        {{ $t('support.main.support_articles.title') }}
      </h1>
      <p class="text-sm text-grey-dark block  mb-5">
        {{ $t('support.articles.this_page') }}
      </p>

      <div v-loading="loading">
        <div class="text-right mb-5 rounded w-4/5">
          <el-input
            v-model="search"
            size="mini"
            :placeholder="$t('support.articles.search_article')"
            @keyup.native.enter="onSearch"
          />
        </div>
        <div class="p-1">
          <div v-if="!filters.search_term && Object.keys(articles).length && articles.articles && articles.articles.length">
            <h4 class="mb-5">
              <span class="align-middle material-icons-outlined text-xl">
                article
              </span>
              <span class="ml-1 align-middle">{{ $t('support.articles.freq_search') }}</span>
            </h4>
            <div
              v-for="(v,i) in articles.articles"
              :key="i"
              class="text-xs"
            >
              <p class="mb-3">
                <span
                  class="text-blue no-underline cursor-pointer hover:text-blue-darker"
                  @click="navigate({ path: `/articles/${v.id}`})"
                > {{ v.title }}</span>
              </p>
            </div>
          </div>
          <div v-if="filters.search_term && Object.keys(articles).length && articles.results && articles.results.length">
            <p class="text-xs mt-2 text-gray-darker">
              {{ articles.count }} {{ $t('support.articles.results_for') }} "{{ filters.search_term }}"
            </p>
            <h4 class="border-b border-bg-grey-lightest py-3">
              {{ $t('support.articles.knowledge_base') }}
            </h4>
            <div
              v-for="(v,i) in articles.results"
              :key="i"
              class="mt-3 border-b border-bg-grey-lightest py-3 hover:bg-grey-lightest"
            >
              <span
                class="block cursor-pointer"
                @click="navigate({ path: `/articles/${v.id}`, query: { search_term: filters.search_term } })"
              >
                <p class="text-blue text-sm mb-2 font-medium truncate hover:text-blue-darker">{{ v.title }}</p>
                <p
                  class="mb-2 text-xs truncate"
                  v-html="sanitizeHtml(v.snippet)"
                />
                <p class="text-xs">
                  <user-detail :author="getSideLoadedAuthor(v.author_id)" />
                  <span class="text-grey">| {{ timeFromNow(v.updated_at, getTz.timeZoneOffset) }}</span>
                </p>
              </span>
            </div>
          </div>
          <div
            v-if="filters.search_term && Object.keys(articles).length && articles.results && !articles.results.length"
            class="text-xs"
          >
            <p class="mt-2">
              {{ (loading)? $t('wait.fetching') : `${$t('support.articles.no_results_for')} '${filters.search_term}'` }}
            </p>
          </div>
          <div
            v-if="!Object.keys(articles).length"
            class="text-xs"
          >
            <p>{{ (loading) ? $t('wait.fetching') : $t('support.articles.no_available') }}</p>
          </div>
        </div>
        <div
          v-if="articles.results && articles.count"
          class="text-right mt-5"
        >
          <el-pagination
            :current-page.sync="filters.page"
            :page-size="filters.per_page"
            layout="prev, pager, next"
            :total="articles.count"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import timeFormatter from '../mixins/timeFormatter';
import utils from '../utils';

import UserDetail from './partials/UserDetail.vue';

export default {
  name: 'SupportArticles',

  components: {
    UserDetail,
  },

  mixins: [timeFormatter],

  data() {
    const defaultPerPage = 25;

    return {
      loading: false,
      defaultPerPage,
      search: '',
      filters: {
        sort_by: 'updated_at',
        sort_order: 'desc',
        search_term: '',
        page: 1,
        per_page: defaultPerPage,
        locale: 'en-US',
      },
    };
  },

  computed: {
    ...mapGetters({
      articles: 'articles/getArticles',
      article: 'articles/getArticle',
      locales: 'articles/getLocales',
      author: 'articles/getAuthor',
      user: 'user/getUser',
      getTz: 'user/getTz',
    }),
  },

  watch: {
    filters: {
      handler() {
        this.fetchData();
      },

      deep: true,
    },

    $route: {
      handler(current) {
        this.clearData();
        let searchTerm = '';

        if (current.query.search_term) {
          searchTerm = this.$route.query.search_term;
          this.search = searchTerm;

          this.filters.search_term = searchTerm;
        } else {
          this.search = '';
          this.fetchData();
        }
      },
    },
  },

  created() {
    this.clearData();
    let searchTerm = '';

    this.fetchLocales();

    if (this.$route.query.search_term) {
      searchTerm = this.$route.query.search_term;
      this.search = searchTerm;

      this.filters.search_term = searchTerm;
    } else {
      this.search = '';
      this.fetchData();
    }
  },

  methods: {
    ...mapActions({
      getArticles: 'articles/getArticles',
      getArticle: 'articles/getArticle',
      getLocales: 'articles/getLocales',
      getAuthor: 'articles/getAuthor',
    }),

    onSearch() {
      this.navigate({ path: '/articles', query: { search_term: this.search.trim() } });
    },

    getSideLoadedAuthor(userId) {
      return this.articles.users.find(u => u.id === userId);
    },

    async fetchLocales() {
      try {
        // this.loading = true;

        await this.getLocales();
      } catch (e) {
        this.$showError(this, e);
      } finally {
        // this.loading = false;
      }
    },

    async fetchData() {
      try {
        this.loading = true;
        let locale = 'en-us';

        if (this.locales.length) {
          // eslint-disable-next-line prefer-destructuring
          locale = this.locales[0].locale;
        }

        const f = { ...this.filters };

        if (f.search_term) {
          f.sort_by = '';
          f.sort_order = '';
        }

        await this.getArticles({ filters: { ...f, ...{ locale } } });
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
      }
    },

    navigate(config) {
      this.$router.push(config);
    },

    sanitizeHtml(content) {
      return utils.sanitizeHtml(content);
    },

    handleSizeChange(size) {
      this.filters = { ...this.filters, per_page: size, page: 1 };
    },

    handleCurrentChange(page) {
      this.filters = { ...this.filters, page };
    },

    clearData() {
      const filters = {
        sort_by: 'updated_at',
        sort_order: 'desc',
        search_term: '',
        page: 1,
        per_page: this.defaultPerPage,
        locale: 'en-US',
      };

      this.filters = { ...filters };
    },
  },
};
</script>
