/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    senderIdData: {},
  },

  getters: {
    getSenderIdData: st => st.senderIdData,
  },

  mutations: {
    SET_SENDER_ID_DATA: (st, payload) => { st.senderIdData = payload; },
  },

  actions,
};
