<template>
  <div class="mb-20">
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      class="mt-5"
    >
      <el-breadcrumb-item :key="1">
        <span
          class="text-blue cursor-pointer"
          @click="navigate({ path: '/'})"
        >
          {{ $t('support.title') }}
        </span>
      </el-breadcrumb-item>
      <el-breadcrumb-item :key="2">
        <span
          class="text-blue cursor-pointer"
          @click="navigate({ path: '/tickets'})"
        >
          {{ $t('support.main.ticket_history.title') }}
        </span>
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :key="3"
        :style="{width: '250px'}"
        class="truncate"
      >
        <span class="text-grey">{{ $t('support.conversation.title') }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <div class="w-3/5 mt-5">
        <h1 class="text-xl text-color font-semibold page-title  mb-5">
          {{ $t('support.conversation.title') }}
        </h1>
        <p class="text-sm text-grey-dark mb-2">
          {{ $t('support.conversation.desc') }}
        </p>
        <p class="text-sm text-grey-dark mb-5">
          {{ $t('support.conversation.this_is_not') }}
        </p>
      </div>
      <div class="flex">
        <div
          v-loading="loading"
          class="w-3/5 pr-8"
        >
          <p
            v-if="getSourceId()"
            class="mb-5 text-grey-dark text-xs w-1/2"
          >
            <el-alert
              type="warning"
              :closable="false"
            >
              <span slot="title">
                {{ $t('support.conversation.this_is_followup') }} <a
                  :href="`/support/tickets/${getSourceId()}/conversation`"
                  class="underline text-blue-light"
                  target="_blank"
                  rel="noreferrer noopener"
                >#{{ getSourceId() }} </a>
              </span>
            </el-alert>
          </p>
          <!-- <pre>{{ JSON.stringify(request, null, 2) }}</pre>
          <pre>{{ JSON.stringify(zendeskUser, null, 2) }}</pre> -->
          <div>
            <h3 class="text-grey-darker text-base truncate">
              {{ transformSubject(request.subject || '') }}
            </h3>
            <div
              v-if="productType && requestType"
              class="mt-2 flex text-xs"
            >
              <span class="text-grey-dark">{{ productType }}</span>
              <span class="text-grey-light ml-1">|</span>
              <span class="text-grey-dark ml-1">{{ requestType }}</span>
            </div>
          </div>
          <div class="flex text-xs mt-5">
            <div>
              <span class="text-grey">{{ $t('column_labels.ticket_id') }}:</span>
              <span class="text-grey-darker font-medium ml-1">{{ request.id }}</span>
              <span class="text-grey-light ml-1">|</span>
            </div>
            <div class="ml-2">
              <span class="text-grey">{{ $t('column_labels.created') }}:</span>
              <span class="text-grey-darker font-medium ml-1">
                {{ formatDate(request.created_at, {utcOffset: getTz.timeZoneOffset}) }}
              </span>
              <span class="text-grey-light ml-1">|</span>
            </div>
            <div class="ml-3">
              <span class="text-grey">{{ $t('column_labels.last_updated') }}:</span>
              <span class="text-grey-darker font-medium ml-1">
                {{ formatDate(request.created_at, {utcOffset: getTz.timeZoneOffset, format: 'D MMM YYYY HH:mm'}) }}
              </span>
              <span class="text-grey-light ml-1">|</span>
            </div>
            <div
              v-if="request.isSenderId"
              class="ml-2 flex"
            >
              <span class="text-grey">{{ $t('column_labels.sid_status') }}:</span>
              <span class="block text-grey-darker font-medium text-xs ml-1 align-top">
                <el-tag
                  size="mini"
                  :type="getSenderIdStatus(senderIdData.RegistrationStatus).statusColor"
                  :style="{fontSize: '9px', marginTop: '-5px'}"
                  class="block"
                >{{ getSenderIdStatus(senderIdData.RegistrationStatus).status.toUpperCase() || '-' }}</el-tag>
              </span>
            </div>
          </div>
          <div class="flex text-xs mt-3 mb-2">
            <div class="flex">
              <span class="text-grey block mr-1">{{ $t('column_labels.ticket_status') }}:</span>
              <span class="block text-grey-darker font-medium text-xs align-top">
                <el-tag
                  v-if="request.status"
                  size="mini"
                  :type="getStatus(request.status).statusColor"
                  class="block"
                  :style="{fontSize: '9px', marginTop: '-5px'}"
                >{{ getStatus(request.status).status.toUpperCase() || '-' }}</el-tag>
              </span>
              <span class="text-grey-light ml-1">|</span>
            </div>
            <div class="ml-2 flex">
              <span class="text-grey block mr-1">{{ $t('column_labels.rating') }}:</span>
              <span class="block text-grey-darker font-medium text-xs align-top">
                <el-tag
                  v-if="request.satisfaction_rating
                    && request.satisfaction_rating.score
                    && ['good', 'bad'].includes(request.satisfaction_rating.score.toLowerCase())"
                  size="mini"
                  :type="request.satisfaction_rating.score.toLowerCase() ==='good' ? 'success' : 'danger'"
                  class="block"
                  :style="{fontSize: '9px', marginTop: '-5px'}"
                >{{ request.satisfaction_rating.score.toUpperCase() }}</el-tag>
                <span v-else>-</span>
                <span class="text-grey-light ml-1">|</span>
              </span>
            </div>
            <div class="ml-2">
              <span class="text-grey">{{ $t('column_labels.requester') }}:</span>
              <span class="text-grey-darker font-medium ml-1">
                {{ !requester.name || requester.name === 'null null' ? 'Customer' : requester.name }}
              </span>
            </div>
          </div>
          <div class="relative">
            <el-tooltip
              effect="dark"
              :content="$t('actions.refresh')"
              placement="top-start"
              class="text-xs"
            >
              <span
                v-if="request.status && request.status.toLowerCase() !== 'closed'"
                class="z-10 material-icons-outlined text-xl cursor-pointer text-grey-dark align-middle block absolute refresh"
                @click="fetchComments(true)"
              >refresh</span>
            </el-tooltip>
          </div>
          <chat-history
            :request="request"
            :refreshing="refreshing"
            @refresh-comment="fetchComments(true)"
          />
          <chat-input
            v-if="request && (request.status || '').toLowerCase() !== 'closed'"
            :request="request"
            @attach-file="uploadModalVisible = true"
            @update-input="updateInput"
            @update-editor="updateEditor"
            @refresh-comment="fetchComments(true)"
          />
          <div
            v-if="request && (request.status || '').toLowerCase() === 'closed'"
            class="mt-5 text-xs"
          >
            <p>
              {{ $t('support.conversation.closed_for_comment') }}
              <span
                v-if="!request.isSenderId"
                class="text-xs text-blue hover:text-blue-light cursor-pointer"
                @click="navigate({ path: '/tickets/create',
                                   query: { request_id: request.id }})"
              >{{ $t('support.conversation.follow_up') }}</span>
              <a
                v-else
                href="/messaging/sender-id"
                class="no-underline text-xs text-blue hover:text-blue-light cursor-pointer"
              >{{ $t('support.conversation.new_sender_id_request') }}</a>.
            </p>
          </div>
          <div class="mt-3 flex">
            <div class="w-2/3">
              <div v-if="currentAttachments.length">
                <p
                  v-for="item in currentAttachments"
                  :key="item.token"
                  class="flex text-xs p-2 hover:bg-grey-lighter current-attachments"
                >
                  <span class="block flex-auto">{{ item.filename }} </span>
                  <span
                    class="block flex-shrink ml-3"
                  >
                    <span
                      class="material-icons-outlined text-purple uploaded block text-sm"
                      @click="removeCurrentAttachment(item)"
                    >
                      check_circle
                    </span>
                    <span
                      class="material-icons-outlined text-red cancel hidden text-sm cursor-pointer"
                      @click="removeCurrentAttachment(item)"
                    >
                      {{ $t('actions.cancel') }}
                    </span>
                  </span>
                </p>
              </div>
            </div>
            <div class="w-1/3 text-right">
              <el-button
                v-if="request && (request.status || '').toLowerCase() !== 'closed'"
                type="primary"
                size="small"
                :loading="loading"
                :disabled="isEmpty"
                @click="handleSubmit"
              >
                {{ $t('actions.submit') }}
              </el-button>
            </div>
          </div>
        </div>

        <div class="w-2/5 py-3 pr-8">
          <div
            class="bg-grey-lightest p-3 rounded mb-5"
          >
            <h4>{{ $t('fields.attachments') }}</h4>
            <div v-if="getAllAttachments().user.length || getAllAttachments().agent.length || getAllAttachments().attachments.length">
              <div
                v-if="getAllAttachments().user.length"
                class="mt-3 mb-2"
              >
                <p class="text-xs mr-2">
                  {{ $t('support.conversation.user_attachments') }}
                </p>
                <div
                  v-for="(v, i) in getAllAttachments().user"
                  :key="i"
                  class="flex mt-1"
                >
                  <div class="truncate">
                    <a
                      href="/"
                      class="text-xs text-blue no-underline hover:text-blue-darker"
                      @click="(ev) => {
                        ev.preventDefault();
                        downloadFile(v);
                      }"
                    >{{ v.file_name }}</a>
                    <p class="text-xs text-grey">
                      {{ parseInt(v.size / 1024, 10).toFixed(2) }}KB
                    </p>
                  </div>
                </div>
              </div>

              <div
                v-if="getAllAttachments().agent.length"
                class="mt-3"
              >
                <div class="flex">
                  <p class="text-xs mr-2">
                    {{ $t('support.conversation.agent_attachments') }}
                  </p>
                  <span class="px-2 rounded bg-red text-white text-xs">{{ $t('support.conversation.for_attention') }}</span>
                </div>
                <div
                  v-for="(v, i) in getAllAttachments().agent"
                  :key="i"
                  class="flex mt-1"
                >
                  <div class="truncate">
                    <a
                      href="/"
                      class="text-xs text-blue no-underline hover:text-blue-darker"
                      @click="(ev) => {
                        ev.preventDefault();
                        downloadFile(v);
                      }"
                    >{{ v.file_name }}</a>
                    <p class="text-xs text-grey">
                      {{ parseInt(v.size / 1024, 10).toFixed(2) }}KB
                    </p>
                  </div>
                </div>
              </div>

              <div
                v-if="getAllAttachments().attachments.length"
                class="mt-3"
              >
                <div
                  v-for="(v, i) in getAllAttachments().attachments"
                  :key="i"
                  class="flex mt-3"
                >
                  <div class="truncate">
                    <a
                      href="/"
                      class="text-xs text-blue no-underline hover:text-blue-darker"
                      @click="(ev) => {
                        ev.preventDefault();
                        downloadFile(v);
                      }"
                    >{{ v.file_name }}</a>
                    <p class="text-xs text-grey">
                      {{ parseInt(v.size / 1024, 10).toFixed(2) }}KB
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="text-xs mt-5 text-grey"
            >
              {{ (!loading)? $t('support.conversation.no_attachments') : $t('wait.fetching') }}
            </div>
          </div>
          <div
            v-if="request.status"
            class="text-center"
          >
            <el-button
              v-if="request.status.toLowerCase() === 'closed' && !request.isSenderId"
              size="small"
              type="primary"
              class="mr-3"
              @click="navigate({ path: '/tickets/create',
                                 query: { request_id: request.id }})"
            >
              {{ $t('support.conversation.create_a_followup') }}
            </el-button>
            <a
              v-if="request.status.toLowerCase() === 'solved' || request.status.toLowerCase() === 'closed'"
              :href="this.request.isSenderId ? '/messaging/sender-id' : '/support/tickets/create'"
            >
              <el-button
                size="small"
                type="primary"
              >
                {{ request.isSenderId ? $t('support.conversation.raise_new_request') : $t('support.conversation.raise_new_ticket') }}
              </el-button>
            </a>
          </div>
          <div
            v-if="request
              && request.status
              && ['solved'].includes(request.status.toLowerCase())
              && request.requester_id
              && zendeskUser
              && zendeskUser.id
              && request.requester_id === zendeskUser.id"
            class="py-10"
          >
            <RatingForm
              :ticket="request"
              :loading="loading"
              @submit="submitRating"
            />
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      ref="uploadModal"
      :title="$t('upload_labels.upload_files')"
      :visible.sync="uploadModalVisible"
      :close-on-click-modal="false"
      :show-close="false"
      width="36%"
      @close="handleBeforeClose"
    >
      <uploader
        :modal-visible="uploadModalVisible"
        cls="support-comment-uploader"
        @set-uploading="setUploading"
        @set-attachments="setAttachments"
      />
      <span
        slot="footer"
        class="dialog-footer text-right"
      >
        <el-button
          size="small"
          :disabled="uploading"
          @click="uploadModalVisible = false"
        >{{ $t('actions.close') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import timeFormatter from '../mixins/timeFormatter';
import utils from '../utils';

import ChatHistory from './partials/ChatHistory.vue';
import ChatInput from './partials/ChatInput.vue';

import Uploader from './partials/Uploader.vue';
// import FileThumbnail from './partials/FileThumbnail.vue';

import RatingForm from './partials/TicketRating.vue';

export default {
  name: 'SupportTicketConversation',

  components: {
    ChatHistory,
    ChatInput,
    Uploader,
    RatingForm,
    // FileThumbnail,
  },

  mixins: [timeFormatter],

  data() {
    return {
      productType: '',
      requestType: '',
      loading: false,
      uploading: false,
      currentAttachments: [],
      uploadModalVisible: false,
      isNoComment: false,
      isEmpty: true,
      editor: null,
      comment: {
        comment_as_html: '',
        comment_as_body: '',
        comment: '',
      },
      refreshing: false,
      timer: 10000,
      counter: 0,
      idled: false,
      interval: null,
      clickedRefresh: false,
      requester: {},
    };
  },

  onIdle() {
    this.clearTimer();
    this.idled = true;
  },
  onActive() {
    this.idled = false;

    if (this.counter === 0 && !this.clickedRefresh) {
      this.setTimer();
    }
  },

  computed: {
    ...mapGetters({
      request: 'tickets/getRequest',
      user: 'user/getUser',
      zendeskUser: 'user/getZendeskUser',
      getTz: 'user/getTz',
      productTypes: 'tickets/getProductTypes',
      requestTypes: 'tickets/getRequestTypes',
      senderIdData: 'senderId/getSenderIdData',
    }),
  },

  created() {
    this.clearRequest({});
    // this.fetchProductAndRequestTypes();
    this.fetchRequest();
  },

  beforeDestroy() {
    this.clearTimer();
  },

  methods: {
    ...mapActions({
      getRequest: 'tickets/getRequest',
      updateRequest: 'tickets/updateRequest',
      getRequestComments: 'tickets/getRequestComments',
      addRequestComment: 'tickets/addRequestComment',
      deleteFile: 'tickets/deleteFile',
      saveFile: 'tickets/downloadFile',
      getRequestTypes: 'tickets/getRequestTypes',
      getProductTypes: 'tickets/getProductTypes',
      makeRating: 'tickets/addRating',
      getRequester: 'user/getZendeskUserById',
      getSenderIdData: 'senderId/getSenderIdData',
    }),

    ...mapMutations({
      clearRequest: 'tickets/CLEAR_REQUEST',
      setRequest: 'tickets/SET_REQUEST',
    }),

    getSourceId() {
      if (this.request && this.request.via && this.request.via.source) {
        const { rel, from } = this.request.via.source;

        if (rel === 'follow_up' && from) {
          return from.ticket_id;
        }
      }

      return '';
    },

    setTypes() {
      this.productTypes.forEach((v) => {
        this.request.custom_fields_filtered.forEach((i) => {
          if (v.value === i.value) {
            this.productType = v.name;
          }
        });
      });
      this.requestTypes.forEach((v) => {
        this.request.custom_fields_filtered.forEach((i) => {
          if (v.value === i.value) {
            this.requestType = v.name;
          }
        });
      });
    },

    async submitRating(ticket, payload) {
      try {
        this.loading = true;
        await this.makeRating({ id: ticket.id, ...payload });

        const { comment, rating: score } = payload;

        const { satisfaction_rating: sr } = this.request;


        // eslint-disable-next-line camelcase
        const satisfaction_rating = { ...sr, comment, score };

        const request = { ...this.request, satisfaction_rating };

        this.setRequest({ ...request });

        this.$message.success(this.$t('support.tickets.thank_you'));
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
      }
    },

    async fetchRequest() {
      try {
        this.clearTimer();
        this.loading = true;
        await this.getRequest({
          id: this.$route.params.id,
        });

        // Get sender id registration data based on zendesk ticket number
        if (this.request.isSenderId) {
          await this.getSenderIdData({
            id: this.$route.params.id,
          });
        }

        await this.getRequestComments({
          id: this.$route.params.id,
        });

        await this.getProductTypes();
        await this.getRequestTypes();


        const { user } = await this.getRequester({ id: this.request.requester_id });

        this.requester = user;

        this.setTypes();

        this.setTimer();
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
      }
    },

    async fetchComments(clickedRefresh) {
      this.clickedRefresh = clickedRefresh;

      try {
        let prevComments = [];

        const req = { ...this.request };

        if (req.comments) {
          prevComments = req.comments;
        }

        if (clickedRefresh) {
          this.clearTimer();
          this.refreshing = true;
        }

        if (!this.$route.params.id
          || !Object.keys(this.request).length
          || (this.request.status && this.request.status.toLowerCase() === 'closed')) {
          this.clearTimer();
          return;
        }

        await this.getRequest({
          id: this.$route.params.id,
        });

        await this.getRequestComments({
          id: this.$route.params.id,
        });

        if (this.request.comments && this.request.comments.length) {
          if (prevComments.length !== this.request.comments.length) {
            // notify user
            this.$notify.success(this.$t('support.conversation.agent_has_replied'));
          }
        }

        if (clickedRefresh) {
          this.setTimer();
        }

        this.clickedRefresh = false;
      } catch (e) {
        this.clearTimer();
        // this.$showError(this, e);
      } finally {
        this.refreshing = false;
        //  do nothing
      }
    },

    setTimer() {
      // fetch every 10 secs if not idled
      if (this.idled) return;

      this.$nextTick(() => {
        if (this.interval) clearInterval(this.interval);
        this.interval = setInterval(() => {
          this.counter = this.counter + 1;
          this.fetchComments();
        }, this.timer);
      });
    },

    clearTimer() {
      this.counter = 0;
      if (this.interval) {
        clearInterval(this.interval);
      }
    },

    getAllAttachments() {
      const attachments = { user: [], agent: [], attachments: [] };

      if (this.request.comments && this.request.comments.length) {
        this.request.comments.forEach((v) => {
          if (v.attachments && v.attachments.length) {
            v.attachments.forEach((a) => {
              if (!a.deleted) {
                if (this.request.isSenderId) {
                  if (v.author_role === 'end-user') {
                    attachments.user.push(a);
                  } else {
                    attachments.agent.push(a);
                  }
                } else {
                  attachments.attachments.push(a);
                }
              }
            });
          }
        });
      }

      return attachments;
    },

    transformSubject(subj) {
      return utils.getSubject(subj);
    },

    getStatus(status) {
      const st = utils.getStatus(status);

      if (st.status === 'awaiting your reply') {
        st.status = this.$t('support.tickets.statuses.awaiting_your_reply');
      } else {
        st.status = this.$t(`support.tickets.statuses.${st.status.toLowerCase()}`);
      }

      return st;
    },

    getSenderIdStatus(status) {
      const senderIdStatus = utils.getSenderIdStatus(status);
      senderIdStatus.status = this.$t(senderIdStatus.status.toLowerCase());
      return senderIdStatus;
    },

    getLocale() {
      return utils.getLocale();
    },

    setUploading(uploading) {
      this.uploading = uploading;
    },

    setAttachments(attachments = []) {
      if (attachments.length) {
        // possible of duplicates
        this.currentAttachments = this.currentAttachments.concat(attachments);
      }

      // get always the unique attachment
      this.currentAttachments = [
        ...new Map(this.currentAttachments.map(item => [item.token, item])).values(),
      ];
    },

    removeCurrentAttachment(item) {
      const self = this;

      this.$confirm(`${self.$t('confirmations.remove', { item: item.filename })}？`, self.$t('app_labels.warning'), {
        confirmButtonText: self.$t('actions.ok'),
        cancelButtonText: self.$t('actions.cancel'),
        type: 'warning',
      }).then(async () => {
        this.currentAttachments = this.currentAttachments.filter(v => v.token !== item.token);

        try {
          await this.deleteFile({ token: item.token });
        } catch (e) {
          // do nothing
        }
      });
    },

    updateEditor(editor = null) {
      this.editor = editor;
    },

    updateInput(obj = {}) {
      const { bodyAsPlain, bodyAsHtml, isEmpty } = obj;

      this.comment = {
        comment_as_html: utils.sanitizeHtml(bodyAsHtml),
        comment_as_plain: bodyAsPlain,
        comment: bodyAsPlain,
      };

      this.isEmpty = isEmpty;
    },

    reset() {
      this.currentAttachments = [];
      this.isEmpty = true;
      const obj = {
        comment_as_html: '',
        comment_as_body: '',
        comment: '',
      };

      if (this.editor) {
        this.editor.commands.clearContent(true);
      }

      this.comment = { ...obj };
    },

    async handleSubmit() {
      if (this.isEmpty) {
        this.$message.error(this.$t('support.conversation.comment_required'));
        return;
      }

      this.clearTimer();

      try {
        this.loading = true;
        await this.addRequestComment({
          ...this.comment,
          attachments: this.currentAttachments.length
            ? this.currentAttachments.map(v => v.token) : [],
          id: this.request.id,
          author_id: this.request.requester_id,
        });

        this.$message.success(this.$t('support.conversation.submitted'));

        this.reset();

        await this.getRequestComments({
          id: this.request.id,
        });
      } catch (err) {
        this.$showError(this, err);
      } finally {
        this.loading = false;
      }
    },

    handleBeforeClose() {
      if (this.$refs.uploadModal) {
        if (this.$refs.uploadModal.$children.length
          && this.$refs.uploadModal.$children.length > 1) {
          if (this.$refs.uploadModal.$children[1].$refs.uploader) {
            this.$refs.uploadModal.$children[1].$refs.uploader.clearFiles();
          }
        }
      }
    },

    downloadFile(file) {
      this.saveFile({ ...file });
    },

    navigate(config) {
      this.$router.push(config);
    },
  },
};
</script>
<style lang="scss">
 .support-comment-uploader {
   .el-upload-dragger {
     width: 470px;
     height: 150px;
   }
 }

 .current-attachments:hover {
   .uploaded {
     display: none;
   }

   .cancel {
     display: block;
   }
 }

 .refresh {
    right: -8px;
    top: -27px;
    height: 30px;
    width: 30px;
  }
</style>
