//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';

export default {
  name: 'SupportChatInput',

  components: {
    EditorContent,
  },

  props: {
    request: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      editor: null,
      empty: true,
      form: {
        columns: 3,
        rows: 3,
      },
      modalVisible: false,
      rules: {
        columns: [
          { required: true, message: 'required', trigger: 'blur' },
          { pattern: /1|2|3|4|5/, message: 'invalid', trigger: 'blur' },
        ],

        rows: [
          { required: true, message: 'required', trigger: 'blur' },
          { pattern: /1|2|3|4|5/, message: 'invalid', trigger: 'blur' },
        ],
      },
    };
  },

  mounted() {
    const self = this;
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3, 4, 5],
          },
        }),
        Link,
        Placeholder.configure({
          placeholder: self.$t('support.chat.type_your'),
        }),
        Table,
        TableRow,
        TableCell,
        TableHeader,
      ],

      onCreate({ editor }) {
        self.$emit('update-editor', editor);
      },

      onUpdate({ editor }) {
        self.empty = editor.isEmpty;

        self.$emit('update-input', {
          bodyAsPlain: editor.getText(),
          bodyAsHtml: editor.getHTML(),
          isEmpty: editor.isEmpty,
        });
      },
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },

  methods: {
    setLink() {
      const self = this;
      this.$prompt(self.$t('support.chat.valid_url'), self.$t('support.chat.set_url'), {
        confirmButtonText: self.$t('actions.ok'),
        cancelButtonText: self.$t('actions.cancel'),
        // eslint-disable-next-line no-useless-escape
        inputPattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
        inputErrorMessage: self.$t('support.chat.must_be'),
      }).then(({ value }) => {
        const previousUrl = this.editor.getAttributes('link').href;
        const url = value || previousUrl;

        // empty
        if (!url) {
          this.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run();

          return;
        }

        // update link
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url, target: '_blank' })
          .run();
      }).catch(() => {
        // do nothing
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run();
      });
    },

    setTables() {
      const self = this;
      this.$refs.form.validate((isValid) => {
        if (isValid) {
          const { rows, columns: cols } = self.form;
          self.editor.chain().focus().insertTable(
            { rows, cols },
          ).run();
          self.$refs.form.resetFields();
          self.modalVisible = false;
        }
      });
    },
  },
};
