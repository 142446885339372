//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import moment from 'moment';

import FileThumbnail from './FileThumbnail.vue';

import timeFormatter from '../../mixins/timeFormatter';
import utils from '../../utils';

export default {
  name: 'SupportChatHistory',

  components: {
    FileThumbnail,
  },

  mixins: [timeFormatter],

  props: {
    request: {
      type: Object,
      default: () => ({}),
    },

    refreshing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {

    };
  },

  computed: {
    ...mapGetters({
      getTz: 'user/getTz',
    }),
  },

  watch: {
    'request.comments': {
      handler(current, old) {
        const notSameLength = old && Array.isArray(old) && current && Array.isArray(current) && old.length !== current.length;
        const hasCurentOnly = !old && current && Array.isArray(current) && current.length;
        if (notSameLength || hasCurentOnly) {
          this.$nextTick(() => {
            const container = this.$refs.chatWindow;
            container.scrollTop = container.scrollHeight + 350;
          });
        }
      },

      deep: true,
    },
  },

  methods: {
    chat(i, request) {
      const utcOffset = this.getTz.timeZoneOffset;
      const previous = request.comments[i - 1];
      const current = request.comments[i];
      const next = request.comments[i + 1];
      // const isRequester = current.author_id === request.requester_id;
      const isMine = request.comments[i].via
        && request.comments[i].via.source
        && request.comments[i].via.source.to
        && request.comments[i].via.source.to.address
        && request.comments[i].via.source.to.address.match(/@8x8.com|@wavecell.zendesk.com/);

      const isSystemGenerated = request.comments[i].metadata
        && request.comments[i].metadata.system
        && request.comments[i].metadata.system.client.match(/Zendesk URL Target/);

      const currentMoment = moment.utc(current.created_at).utcOffset(utcOffset, false);

      let prevBySameUser = false;
      let nextBySameUser = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;
      let showMsgTime = true;

      if (previous) {
        const previousMoment = moment.utc(previous.created_at).utcOffset(utcOffset, false);
        const previousDuration = moment.duration(
          currentMoment.diff(previousMoment),
        );
        prevBySameUser = previous.author_id === current.author_id;
        if (prevBySameUser && previousDuration.as('hours') < 1) {
          startsSequence = false;
        }
        if (previousDuration.as('hours') < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        const nextMoment = moment.utc(next.created_at).utcOffset(utcOffset, false);
        const nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameUser = next.author_id === current.author_id;
        if (nextBySameUser && nextDuration.as('hours') < 1) {
          endsSequence = false;
        }
      }

      if (request.comments.length && request.comments.length - 1 !== i) {
        showMsgTime = false;
      }
      return {
        isMine,
        isSystemGenerated,
        startsSequence,
        endsSequence,
        showTimestamp,
        showMsgTime,
      };
    },

    sanitizeHtml(content) {
      return utils.sanitizeHtml(content);
    },

    getBody(htmlbody, plainbody, index) {
      let comment = utils.getBody(htmlbody, plainbody);

      // Check if it's the first comment in the sender id ticket
      // Contains the copy of the form
      if (this.request.isSenderId && index === 0) {
        const titleIndex = comment.indexOf('</p>');
        comment = `${comment.slice(0, titleIndex + 4)}<p></p><p></p>${comment.slice(titleIndex + 4)}`;
        return comment.replace(/\n/g, '');
      }

      return comment.replace(/\n/g, '<p></p>');
    },

    getAttachments(attachments) {
      return attachments.filter(v => !v.deleted);
    },
  },
};
