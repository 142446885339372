<template>
  <div class="mb-20">
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      class="mt-5"
    >
      <el-breadcrumb-item :key="1">
        <span
          class="text-blue cursor-pointer"
          @click="navigate({ path: '/'})"
        >
          {{ $t('support.main.support_overview.title') }}
        </span>
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :key="2"
        :style="{width: '250px'}"
        class="truncate"
      >
        <span class="text-grey"> {{ $t('support.main.raise_ticket.title') }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div
      class="w-3/5 mt-5"
    >
      <h1 class="text-xl text-color font-semibold page-title mb-5">
        {{ $t('support.main.raise_ticket.title') }}
      </h1>
      <p class="text-sm text-grey-dark block  mb-5">
        {{ $t('support.main.raise_ticket.page_desc') }}
      </p>
      <p
        v-if="request && request.id"
        class="mt-5 mb-5 text-grey-dark text-xs w-3/5"
      >
        <el-alert
          :title="`${$t('support.create_ticket.follow_up')} #${request.id}`"
          type="warning"
          :closable="false"
        />
      </p>
      <el-form
        ref="form"
        v-loading="loading"
        :model="form"
        :rules="rules"
        label-position="top"
        class="support-form w-3/5"
      >
        <el-form-item
          prop="product"
          :label="$t('fields.product')"
        >
          <el-select
            v-model="form.product"
            :disabled="loading"
            class="w-full"
            size="small"
            :placeholder="$t('validations.select', { value: $t('fields.product').toLowerCase() })"
          >
            <el-option
              v-for="item in productTypes"
              :key="item.id"
              :label="getProductTypeLabel(item.name.trim())"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="requestType"
          :label="$t('fields.request_type')"
        >
          <el-select
            v-model="form.requestType"
            :disabled="loading"
            class="w-full"
            size="small"
            :placeholder="$t('support.raise_ticket.request_type_reminder')"
          >
            <el-option
              v-for="item in requestTypes"
              :key="item.id"
              :label="getRequestTypeLabel(item.name)"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$tc('column_labels.subaccount', 1)"
        >
          <el-select
            v-model="form.subaccount"
            :disabled="loading"
            class="w-full"
            size="small"
            :placeholder="`${$t('validations.select', { value: $tc('column_labels.subaccount', 1).toLowerCase() })} (${$t('validations.optional')})`"
          >
            <el-option
              v-for="item in subaccounts"
              :key="item.SubAccountUid"
              :label="item.SubAccountId"
              :value="item.SubAccountId"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item
          prop="ccEmail"
          size="small"
          :label="$t('fields.cc_email')"
        >
          <el-input
            v-model="form.ccEmail"
            :disabled="loading"
            class="w-full"
            :placeholder="$t('fields.cc_email')"
          />
        </el-form-item> -->
        <el-form-item
          prop="subject"
          size="small"
          :label="$t('fields.subject')"
        >
          <el-input
            v-model="form.subject"
            :disabled="loading"
            class="w-full"
            size="small"
            :placeholder="$t('fields.subject')"
          />
        </el-form-item>
        <el-form-item
          :label="$t('fields.additional_comments')"
          prop="comment"
        >
          <el-input
            v-model="form.comment"
            :disabled="loading"
            class="w-full"
            size="small"
            prop="comment"
            type="textarea"
            :placeholder="$t('support.raise_ticket.additional_comment_reminder')"
            :autosize="{ minRows: 6, maxRows: 8}"
          />
        </el-form-item>
        <el-form-item :label="$t('fields.attachments')">
          <uploader
            ref="formUploader"
            :modal-visible="false"
            cls="support-create-uploader"
            @set-uploading="setUploading"
            @set-attachments="setAttachments"
          />
        </el-form-item>
        <el-form-item class="text-right">
          <el-button
            :disabled="loading"
            size="small"
            @click="handleCancel"
          >
            {{ $t('actions.back') }}
          </el-button>
          <el-button
            :loading="loading"
            :disabled="uploading"
            type="primary"
            size="small"
            @click="handleSubmit"
          >
            {{ $t('actions.submit') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex';

import Uploader from './partials/Uploader.vue';

import utils from '../utils';

export default {
  name: 'SupportCreateTicket',

  components: {
    Uploader,
  },

  data() {
    const errorMsgs = {
      product: this.$t('validations.required', { value: this.$t('fields.product') }),
      subject: this.$t('validations.required', { value: this.$t('fields.subject') }),
      comment: this.$t('validations.required', { value: this.$t('fields.additional_comments') }),
      requestType: this.$t('validations.required', { value: this.$t('fields.request_type') }),
      // ccEmail: this.$t('validations.valid', { value: this.$t('fields.email').toLowerCase() }),
    };

    return {
      attachments: [],
      form: {
        product: '',
        subject: '',
        comment: '',
        requestType: '',
        // ccEmail: '',
        subaccount: '',
      },

      rules: {
        product: [
          { required: true, message: errorMsgs.product, trigger: 'change' },
        ],
        subject: [
          { required: true, message: errorMsgs.subject, trigger: 'blur' },
        ],
        comment: [
          { required: true, message: errorMsgs.comment, trigger: 'blur' },
        ],
        requestType: [
          {
            required: true, message: errorMsgs.requestType, trigger: 'change',
          },
        ],
        // ccEmail: [
        //   {
        //     type: 'email', message: errorMsgs.ccEmail, trigger: ['blur', 'change'],
        //   },
        // ],
      },

      loading: false,
      uploading: false,
    };
  },

  computed: {
    ...mapGetters({
      productTypes: 'tickets/getProductTypes',
      requestTypes: 'tickets/getRequestTypes',
      user: 'user/getUser',
      subaccounts: 'user/getSubaccounts',
      request: 'tickets/getRequest',
    }),
  },

  created() {
    this.clearRequest({});
    this.fetchSubaccounts();
    this.fetchData();
  },

  methods: {
    ...mapActions({
      getRequestTypes: 'tickets/getRequestTypes',
      getProductTypes: 'tickets/getProductTypes',
      createRequest: 'tickets/createRequest',
      getRequest: 'tickets/getRequest',
      uploadFile: 'tickets/uploadFile',
      deleteFile: 'tickets/deleteFile',
      getSubaccounts: 'user/getSubaccounts',
    }),

    ...mapMutations({
      clearRequest: 'tickets/CLEAR_REQUEST',
    }),

    async fetchSubaccounts() {
      try {
        await this.getSubaccounts({ accountUid: this.user.AccountUid });
      } catch (e) {
        this.$showError(this, e);
      }
    },

    async fetchData() {
      const { query } = this.$route;
      const { request_id: id } = query;
      try {
        this.loading = true;

        if (id) {
          await this.getRequest({ id });
          // console.log('this.request', this.request);
        }

        await this.getProductTypes();
        await this.getRequestTypes();

        if (this.request.custom_fields && this.request.custom_fields.length) {
          this.form.subject = utils.getSubject(this.request.subject);
          this.form.product = this.request.custom_fields[1].value;
          this.form.requestType = this.request.custom_fields[2].value;
          this.form.comment = this.request.custom_fields[3].value;
        }
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
      }
    },

    handleSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const {
            product,
            subject,
            comment: c,
            requestType,
            // ccEmail,
            subaccount,
          } = this.form;

          const sourceId = this.request.id ? this.request.id.toString() : '';
          const followUp = this.request.id ? `${this.$t('support.create_ticket.follow_up_for')} #${sourceId}***` : '';
          const comment = c.trim();

          const payload = {
            subject: subject.trim(),
            request_type: requestType,
            // cc_email: ccEmail || '',
            via_followup_source_id: sourceId,
            description: `${followUp}${subject}***${comment}`,
            product_type: product,
            additional_comments: comment,
            attachments: this.attachments.length ? this.attachments.map(v => v.token) : [],
            subAccountId: subaccount,
          };

          try {
            this.loading = true;
            const { id } = await this.createRequest(payload);

            this.$message.success(this.$t('success.created', { value: `${this.$t('column_labels.ticket')} #${id}` }));

            this.reset();
            this.$router.push({ path: '/tickets' });
          } catch (e) {
            this.$showError(this, e);
          } finally {
            this.loading = false;
          }

          return true;
        }

        // this.$message.error(this.$t('validations.required_fields'));
        return false;
      });
    },

    handleCancel() {
      this.reset();

      this.$router.push('/');
    },

    reset() {
      const { form, formUploader } = this.$refs;


      if (form) {
        form.resetFields();
      }

      if (formUploader.$refs.uploader) {
        formUploader.$refs.uploader.clearFiles();
      }

      this.clearRequest({});

      this.attachments = [];
    },

    getProductTypeLabel(label) {
      const products = [
        {
          name: 'SMS',
          label: this.$t('sidebar_menu_parent.SMS'),
        },
        {
          name: 'SMS Engage',
          label: this.$t('mcs.sms_engage'),
        },
        {
          name: 'Chat Apps',
          label: this.$t('sidebar_menu_parent.Chat apps'),
        },
        {
          name: 'Video Interaction',
          label: this.$t('sidebar_menu_parent.Video interaction'),
        },

        {
          name: 'Voice',
          label: this.$t('sidebar_menu_parent.Voice'),
        },

        {
          name: 'Others',
          label: this.$t('support.request_types.others'),
        },
      ];


      const hasLabel = products.find(v => v.name === label);

      if (hasLabel) {
        return hasLabel.label;
      }

      return label;
    },

    getRequestTypeLabel(label) {
      const requestTypes = [
        {
          name: 'General Query',
          label: this.$t('support.request_types.general_query'),
        },
        {
          name: 'Account Change',
          label: this.$t('support.request_types.account_change'),
        },
        {
          name: 'Payment Issue',
          label: this.$t('support.request_types.payment_issue'),
        },
        {
          name: 'Dashboard Issue',
          label: this.$t('support.request_types.dashboard_issue'),
        },

        {
          name: 'Reporting Issue',
          label: this.$t('support.request_types.reporting_issue'),
        },

        {
          name: 'Price Change',
          label: this.$t('support.request_types.price_change'),
        },

        {
          name: 'Service Outage',
          label: this.$t('support.request_types.service_outage'),
        },

        {
          name: 'Others',
          label: this.$t('support.request_types.others'),
        },
      ];

      const hasLabel = requestTypes.find(v => v.name === label);

      if (hasLabel) {
        return hasLabel.label;
      }
      return label;
    },

    setUploading(loading) {
      this.uploading = loading;
    },

    setAttachments(attachments) {
      this.attachments = attachments;
    },

    navigate(config) {
      this.$router.push(config);
    },
  },
};
</script>
<style lang="scss">
 .support-create-uploader {
   .el-upload-dragger {
     width: 400px;
     height: 150px;
   }
 }

 .support-form {
   .el-form-item__label {
      padding: 0 !important;
      line-height: 25px !important;
   }
 }
</style>
