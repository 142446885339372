<template>
  <div class="mb-20 w-3/5">
    <div v-loading="loading">
      <div class="mb-5 mt-5">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :key="0">
            <span
              class="text-blue cursor-pointer"
              @click="navigate({ path: '/'})"
            >
              {{ $t('support.main.support_overview.title') }}
            </span>
          </el-breadcrumb-item>
          <el-breadcrumb-item :key="1">
            <span
              class="text-blue cursor-pointer"
              @click="navigate({ path: '/articles'})"
            >
              {{ $t('support.articles.article_main_page') }}
            </span>
          </el-breadcrumb-item>
          <el-breadcrumb-item
            v-if="$route.query.search_term"
            :key="2"
            class="truncate"
          >
            <span
              class="text-blue cursor-pointer"
              @click="navigate({ path: '/articles', query: { ...$route.query }})"
            >>{{ $t('support.articles.search_results') }}</span>
          </el-breadcrumb-item>
          <el-breadcrumb-item
            :key="3"
            :style="{width: '250px'}"
            class="truncate"
          >
            <span class="text-grey">{{ article.title }}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="text-right mb-5 bg-grey-lightest p-3 rounded">
        <el-input
          v-model="search"
          class="w-2/5"
          size="mini"
          :placeholder="$t('support.articles.search_article')"
          @keyup.native.enter="onSearch"
        />
      </div>
      <h4 class="mb-2">
        <span>{{ article.title || '' }}</span>
      </h4>
      <p
        v-if="author.name"
        class="text-xs text-grey mb-10"
      >
        {{ author.name }} | {{ timeFromNow(article.updated_at, getTz.timeZoneOffset) }}
      </p>
      <div
        v-if="article.body"
        class="article"
        v-html="sanitizeHtml(article.body)"
      />
      <div
        v-else
        class="text-xs"
      >
        {{ (loading) ? $t('wait.fetching'): $t('support.articles.no_article') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import utils from '../utils';
import timeFormatter from '../mixins/timeFormatter';

export default {
  name: 'SupportArticle',

  components: {

  },

  mixins: [timeFormatter],

  data() {
    return {
      loading: false,
      search: '',
    };
  },

  computed: {
    ...mapGetters({
      article: 'articles/getArticle',
      locales: 'articles/getLocales',
      author: 'articles/getAuthor',
      user: 'user/getUser',
      getTz: 'user/getTz',
    }),
  },

  created() {
    this.search = '';
    this.setArticle({});
    this.fetchLocales();
    this.fetchData();
  },

  methods: {
    ...mapActions({
      getArticles: 'articles/getArticles',
      getArticle: 'articles/getArticle',
      getLocales: 'articles/getLocales',
    }),

    ...mapMutations({
      setArticle: 'articles/SET_ARTICLE',
    }),

    onSearch() {
      this.$router.replace({ path: '/articles', query: { search_term: this.search.trim() } });
    },

    async fetchLocales() {
      try {
        // this.loading = true;

        await this.getLocales();
      } catch (e) {
        this.$showError(this, e);
      } finally {
        // this.loading = false;
      }
    },

    async fetchData() {
      try {
        this.loading = true;
        const locale = this.locales.length ? this.locales[0].locale : 'en-US';

        await this.getArticle({ id: this.$route.params.id, locale });
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
      }
    },

    navigate(config) {
      this.$router.push(config);
    },

    sanitizeHtml(content) {
      return utils.sanitizeHtml(content);
    },
  },
};
</script>
<style lang="scss">
  /* @import './styles/article.css'; */
  .article {
    word-wrap: break-word;
    line-height: 1.7;

    p {
      margin-bottom: 10px;
    }
    ul,
    ol {
      padding: 0 1rem;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 3px solid rgba(#0D0D0D, 0.1);
    }

    a {
      color: #0056e0;
    }

    code {
      font-size: 12px;
      padding: 0.25em;
      border-radius: 0.25em;
      background-color: #F8F9F9;
      border-color: #C2C8CC;
      color: #616161;
      box-decoration-break: clone;
    }

    pre {
      background: #f9f9f9;
      border: 1px solid #eee;
      border-radius: 3px;
      padding: 10px 15px;
      overflow: auto;
      white-space: pre;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    hr {
      border-top: 1px solid #C2C8CC;
    }

    table {
      font-size: 11px;
      width: 100%;
    }

    table, th, td {
      border: 1px solid #616161;
    }

    table td, table th {
      padding: 2px;
      min-width: 33.333%;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
    }

    table tr:first-child {
      font-weight: 500;
    }
    .wysiwyg-color-black {
      color: #000;
    }
    .wysiwyg-color-black70 {
      color: #4d4d4d;
    }
    .wysiwyg-color-black60 {
      color: #666;
    }
    .wysiwyg-color-black50 {
      color: gray;
    }
    .wysiwyg-color-black40 {
      color: #999;
    }
    .wysiwyg-color-black30 {
      color: #b3b3b3;
    }
    .wysiwyg-color-black20 {
      color: #ccc;
    }
    .wysiwyg-color-black10 {
      color: #e6e6e6;
    }
    .wysiwyg-color-red {
      color: red;
    }
    .wysiwyg-color-orange {
      color: #f90;
    }
    .wysiwyg-color-yellow {
      color: #ff0;
    }
    .wysiwyg-color-green {
      color: lime;
    }
    .wysiwyg-color-cyan {
      color: aqua;
    }
    .wysiwyg-color-blue {
      color: blue;
    }
    .wysiwyg-color-purple {
      color: #90f;
    }
    .wysiwyg-color-pink {
      color: #f0f;
    }
    .wysiwyg-color-red90 {
      color: #f33;
    }
    .wysiwyg-color-red80 {
      color: #f66;
    }
    .wysiwyg-color-red70 {
      color: #f99;
    }
    .wysiwyg-color-red110 {
      color: #c00;
    }
    .wysiwyg-color-red120 {
      color: #900;
    }
    .wysiwyg-color-red130 {
      color: #600;
    }
    .wysiwyg-color-orange90 {
      color: #ffad33;
    }
    .wysiwyg-color-orange80 {
      color: #ffc266;
    }
    .wysiwyg-color-orange70 {
      color: #ffd699;
    }
    .wysiwyg-color-orange110 {
      color: #cc7a00;
    }
    .wysiwyg-color-orange120 {
      color: #995c00;
    }
    .wysiwyg-color-orange130 {
      color: #663d00;
    }
    .wysiwyg-color-yellow90 {
      color: #ff3;
    }
    .wysiwyg-color-yellow80 {
      color: #ff6;
    }
    .wysiwyg-color-yellow70 {
      color: #ff9;
    }
    .wysiwyg-color-yellow110 {
      color: #cc0;
    }
    .wysiwyg-color-yellow120 {
      color: #990;
    }
    .wysiwyg-color-yellow130 {
      color: #660;
    }
    .wysiwyg-color-green90 {
      color: #3f3;
    }
    .wysiwyg-color-green80 {
      color: #6f6;
    }
    .wysiwyg-color-green70 {
      color: #9f9;
    }
    .wysiwyg-color-green110 {
      color: #0c0;
    }
    .wysiwyg-color-green120 {
      color: #090;
    }
    .wysiwyg-color-green130 {
      color: #060;
    }
    .wysiwyg-color-cyan90 {
      color: #3ff;
    }
    .wysiwyg-color-cyan80 {
      color: #6ff;
    }
    .wysiwyg-color-cyan70 {
      color: #9ff;
    }
    .wysiwyg-color-cyan110 {
      color: #0cc;
    }
    .wysiwyg-color-cyan120 {
      color: #099;
    }
    .wysiwyg-color-cyan130 {
      color: #066;
    }
    .wysiwyg-color-blue90 {
      color: #33f;
    }
    .wysiwyg-color-blue80 {
      color: #66f;
    }
    .wysiwyg-color-blue70 {
      color: #99f;
    }
    .wysiwyg-color-blue110 {
      color: #00c;
    }
    .wysiwyg-color-blue120 {
      color: #009;
    }
    .wysiwyg-color-blue130 {
      color: #006;
    }
    .wysiwyg-color-purple90 {
      color: #ad33ff;
    }
    .wysiwyg-color-purple80 {
      color: #c266ff;
    }
    .wysiwyg-color-purple70 {
      color: #d699ff;
    }
    .wysiwyg-color-purple110 {
      color: #7a00cc;
    }
    .wysiwyg-color-purple120 {
      color: #5c0099;
    }
    .wysiwyg-color-purple130 {
      color: #3d0066;
    }
    .wysiwyg-color-pink90 {
      color: #f3f;
    }
    .wysiwyg-color-pink80 {
      color: #f6f;
    }
    .wysiwyg-color-pink70 {
      color: #f9f;
    }
    .wysiwyg-color-pink110 {
      color: #c0c;
    }
    .wysiwyg-color-pink120 {
      color: #909;
    }
    .wysiwyg-color-pink130 {
      color: #606;
    }
    .wysiwyg-font-size-x-large {
      font-size: x-large;
    }
    .wysiwyg-font-size-large {
      font-size: large;
    }
    .wysiwyg-font-size-medium {
      font-size: small;
    }
    .wysiwyg-font-size-small {
      font-size: x-small;
    }
    .wysiwyg-underline {
      text-decoration: underline;
    }
    .wysiwyg-text-align-right {
      text-align: right;
    }
    .wysiwyg-text-align-center {
      text-align: center;
    }
    .wysiwyg-text-align-left {
      text-align: left;
    }
    .wysiwyg-text-align-justify {
      text-align: justify;
    }
    .wysiwyg-indent1 {
      padding-left: 10px;
    }
    [dir="rtl"] .wysiwyg-indent1 {
      padding-left: auto;
      padding-right: 10px;
    }
    .wysiwyg-indent2 {
      padding-left: 20px;
    }
    [dir="rtl"] .wysiwyg-indent2 {
      padding-left: auto;
      padding-right: 20px;
    }
    .wysiwyg-indent3 {
      padding-left: 30px;
    }
    [dir="rtl"] .wysiwyg-indent3 {
      padding-left: auto;
      padding-right: 30px;
    }
    .wysiwyg-indent4 {
      padding-left: 40px;
    }
    [dir="rtl"] .wysiwyg-indent4 {
      padding-left: auto;
      padding-right: 40px;
    }
    .wysiwyg-indent5 {
      padding-left: 50px;
    }
    [dir="rtl"] .wysiwyg-indent5 {
      padding-left: auto;
      padding-right: 50px;
    }
    .wysiwyg-indent6 {
      padding-left: 60px;
    }
    [dir="rtl"] .wysiwyg-indent6 {
      padding-left: auto;
      padding-right: 60px;
    }
    .wysiwyg-indent7 {
      padding-left: 70px;
    }
    [dir="rtl"] .wysiwyg-indent7 {
      padding-left: auto;
      padding-right: 70px;
    }
    .wysiwyg-indent8 {
      padding-left: 80px;
    }
    [dir="rtl"] .wysiwyg-indent8 {
      padding-left: auto;
      padding-right: 80px;
    }
    .wysiwyg-indent9 {
      padding-left: 90px;
    }
    [dir="rtl"] .wysiwyg-indent9 {
      padding-left: auto;
      padding-right: 90px;
    }
    .wysiwyg-indent10 {
      padding-left: 100px;
    }
    [dir="rtl"] .wysiwyg-indent10 {
      padding-left: auto;
      padding-right: 100px;
    }
    .wysiwyg-indent11 {
      padding-left: 110px;
    }
    [dir="rtl"] .wysiwyg-indent11 {
      padding-left: auto;
      padding-right: 110px;
    }
    .wysiwyg-indent12 {
      padding-left: 120px;
    }
    [dir="rtl"] .wysiwyg-indent12 {
      padding-left: auto;
      padding-right: 120px;
    }
    .wysiwyg-indent13 {
      padding-left: 130px;
    }
    [dir="rtl"] .wysiwyg-indent13 {
      padding-left: auto;
      padding-right: 130px;
    }
    .wysiwyg-indent14 {
      padding-left: 140px;
    }
    [dir="rtl"] .wysiwyg-indent14 {
      padding-left: auto;
      padding-right: 140px;
    }
    .wysiwyg-indent15 {
      padding-left: 150px;
    }
    [dir="rtl"] .wysiwyg-indent15 {
      padding-left: auto;
      padding-right: 150px;
    }
    .wysiwyg-indent16 {
      padding-left: 160px;
    }
    [dir="rtl"] .wysiwyg-indent16 {
      padding-left: auto;
      padding-right: 160px;
    }
    .wysiwyg-indent17 {
      padding-left: 170px;
    }
    [dir="rtl"] .wysiwyg-indent17 {
      padding-left: auto;
      padding-right: 170px;
    }
    .wysiwyg-indent18 {
      padding-left: 180px;
    }
    [dir="rtl"] .wysiwyg-indent18 {
      padding-left: auto;
      padding-right: 180px;
    }
    .wysiwyg-indent19 {
      padding-left: 190px;
    }
    [dir="rtl"] .wysiwyg-indent19 {
      padding-left: auto;
      padding-right: 190px;
    }
    .wysiwyg-indent20 {
      padding-left: 200px;
    }
    [dir="rtl"] .wysiwyg-indent20 {
      padding-left: auto;
      padding-right: 200px;
    }

  }
</style>
