<template>
  <div
    v-if="editor"
    class="chat-input mt-5"
    :class="empty ? 'is-empty': 'is-not-empty'"
  >
    <div class="chat-input__buttons mb-2 flex">
      <div class="flex">
        <el-popover
          placement="top-start"
          :title="$t('support.chat.wysywyg.headings')"
          width="80"
          trigger="hover"
          class="block"
        >
          <div class="headings">
            <h1
              class="cursor-pointer w-12 p-1"
              :class="{ 'bg-grey-lighter': editor.isActive('heading', {level: 1 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            >
              H1
            </h1>
            <h2
              class="cursor-pointer w-12 p-1"
              :class="{ 'bg-grey-lighter': editor.isActive('heading', {level: 2 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            >
              H2
            </h2>
            <h3
              class="cursor-pointer w-12 p-1"
              :class="{ 'bg-grey-lighter': editor.isActive('heading', {level: 3 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            >
              H3
            </h3>
            <h4
              class="cursor-pointer w-12 p-1"
              :class="{ 'bg-grey-lighter': editor.isActive('heading', {level: 4 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
            >
              H4
            </h4>
            <h5
              class="cursor-pointer w-12 p-1"
              :class="{ 'bg-grey-lighter': editor.isActive('heading', { level: 5 }) }"
              @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
            >
              H5
            </h5>
          </div>
          <button
            slot="reference"
            :class="{ 'bg-grey-lighter': editor.isActive('heading', { level: 1 })
              || editor.isActive('heading', { level: 2 })
              || editor.isActive('heading', { level: 3 })
              || editor.isActive('heading', { level: 4 })
              || editor.isActive('heading', { level: 5 }) }"
            class="editor-buttons border border-grey-light rounded-sm p-1 w-6 h-6"
          >
            <span class="text-xs material-icons-outlined font-semibold text-grey-dark hover:text-grey-darkest">
              format_size
            </span>
          </button>
        </el-popover>
        <el-tooltip
          effect="dark"
          :title="$t('support.chat.wysywyg.bold')"
          placement="top-start"
          class="text-xs block ml-1"
        >
          <button
            :class="{ 'bg-grey-lighter' : editor.isActive('bold') }"
            class="editor-buttons border border-grey-light rounded-sm p-1 w-6 h-6"
            @click="editor.chain().focus().toggleBold().run()"
          >
            <span class="text-xs material-icons-outlined font-semibold text-grey-dark hover:text-grey-darkest">
              format_bold
            </span>
          </button>
        </el-tooltip>
        <el-tooltip
          effect="dark"
          :title="$t('support.chat.wysywyg.italic')"
          placement="top-start"
          class="text-xs block ml-1"
        >
          <button
            :class="{ 'bg-grey-lighter' : editor.isActive('italic') }"
            class="editor-buttons border border-grey-light rounded-sm p-1 w-6 h-6"
            @click="editor.chain().focus().toggleItalic().run()"
          >
            <span class="text-xs material-icons-outlined font-semibold text-grey-dark hover:text-grey-darkest">
              format_italic
            </span>
          </button>
        </el-tooltip>
      </div>
      <div class="ml-5">
        <el-tooltip
          effect="dark"
          :title="$t('support.chat.wysywyg.bullet_list')"
          placement="top-start"
          class="text-xs"
        >
          <button
            :class="{ 'bg-grey-lighter' : editor.isActive('bulletList') }"
            class="editor-buttons border border-grey-light rounded-sm p-1 w-6 h-6"
            @click="editor.chain().focus().toggleBulletList().run()"
          >
            <span class="text-xs material-icons-outlined font-semibold text-grey-dark hover:text-grey-darkest">
              format_list_bulleted
            </span>
          </button>
        </el-tooltip>
        <el-tooltip
          effect="dark"
          :title="$t('support.chat.wysywyg.numbered_list')"
          placement="top-start"
          class="text-xs"
        >
          <button
            :class="{ 'bg-grey-lighter' : editor.isActive('orderedList') }"
            class="editor-buttons border border-grey-light rounded-sm p-1 w-6 h-6"
            @click="editor.chain().focus().toggleOrderedList().run()"
          >
            <span class="text-xs material-icons-outlined font-semibold text-grey-dark hover:text-grey-darkest">
              format_list_numbered
            </span>
          </button>
        </el-tooltip>
      </div>
      <div class="ml-5">
        <el-tooltip
          effect="dark"
          :title="$t('support.chat.wysywyg.blockquote')"
          placement="top-start"
          class="text-xs"
        >
          <button
            :class="{ 'bg-grey-lighter' : editor.isActive('blockquote') }"
            class="editor-buttons border border-grey-light rounded-sm p-1 w-6 h-6"
            @click="editor.chain().focus().toggleBlockquote().run()"
          >
            <span class="text-xs material-icons-outlined font-semibold text-grey-dark hover:text-grey-darkest">
              format_quote
            </span>
          </button>
        </el-tooltip>
        <el-tooltip
          effect="dark"
          :title="$t('support.chat.wysywyg.code')"
          placement="top-start"
          class="text-xs"
        >
          <button
            :class="{ 'bg-grey-lighter' : editor.isActive('code') }"
            class="editor-buttons border border-grey-light rounded-sm p-1 w-6 h-6"
            @click="editor.chain().focus().toggleCode().run()"
          >
            <span class="text-xs material-icons-outlined font-semibold text-grey-dark hover:text-grey-darkest">
              code
            </span>
          </button>
        </el-tooltip>
        <el-tooltip
          effect="dark"
          :title="$t('support.chat.wysywyg.code_block')"
          placement="top-start"
          class="text-xs"
        >
          <button
            :class="{ 'bg-grey-lighter' : editor.isActive('codeBlock') }"
            class="editor-buttons border border-grey-light rounded-sm p-1 w-6 h-6"
            @click="editor.chain().focus().toggleCodeBlock().run()"
          >
            <span class="text-xs material-icons-outlined font-semibold text-grey-dark hover:text-grey-darkest">
              integration_instructions
            </span>
          </button>
        </el-tooltip>
        <el-tooltip
          effect="dark"
          :title="$t('support.chat.wysywyg.hyperlink')"
          placement="top-start"
          class="text-xs"
        >
          <button
            :class="{ 'bg-grey-lighter' : editor.isActive('link') }"
            class="editor-buttons border border-grey-light rounded-sm p-1 w-6 h-6"
            @click="setLink"
          >
            <span class="text-xs material-icons-outlined font-semibold text-grey-dark hover:text-grey-darkest">
              link
            </span>
          </button>
        </el-tooltip>
        <el-tooltip
          effect="dark"
          :title="$t('support.chat.wysywyg.horizontal_rule')"
          placement="top-start"
          class="text-xs"
        >
          <button
            class="editor-buttons border border-grey-light rounded-sm p-1 w-6 h-6"
            @click="editor.chain().focus().setHorizontalRule().run()"
          >
            <span class="text-xs material-icons-outlined font-semibold text-grey-dark hover:text-grey-darkest">
              horizontal_rule
            </span>
          </button>
        </el-tooltip>
      </div>
      <div class="ml-5 text-right">
        <el-tooltip
          effect="dark"
          :title="$t('support.chat.wysywyg.table')"
          placement="top-start"
          class="text-xs"
        >
          <button
            class="editor-buttons border border-grey-light rounded-sm p-1 w-6 h-6"
            @click="modalVisible = true"
          >
            <span class="text-xs material-icons-outlined font-semibold text-grey-dark hover:text-grey-darkest">
              table_rows
            </span>
          </button>
        </el-tooltip>
        <el-tooltip
          effect="dark"
          :title="$t('support.chat.wysywyg.attachments')"
          placement="top-start"
          class="text-xs"
        >
          <button
            class="editor-buttons border border-grey-light rounded-sm p-1 w-6 h-6"
            @click="$emit('attach-file')"
          >
            <span class="text-xs material-icons-outlined font-semibold text-grey-dark hover:text-grey-darkest">
              attach_file
            </span>
          </button>
        </el-tooltip>
      </div>
      <div
        v-if="request.status && request.status.toLowerCase() !== 'closed'"
        class="w-2/5 text-right"
      >
        <el-tooltip
          effect="dark"
          :content="$t('actions.refresh')"
          placement="top-start"
          class="text-xs"
        >
          <button
            class="editor-buttons p-1 w-6 h-6"
            @click="$emit('refresh-comment')"
          >
            <span class="text-xs material-icons-outlined text-xl text-grey-dark hover:text-grey-darkest">
              refresh
            </span>
          </button>
        </el-tooltip>
      </div>
    </div>
    <editor-content :editor="editor" />
    <el-dialog
      :title="$t('support.chat.add_table')"
      :visible.sync="modalVisible"
      :close-on-click-modal="false"
      :show-close="false"
      width="22%"
      @close="handleBeforeClose"
    >
      <p class="text-xs text-grey mb-2">
        {{ $t('support.chat.should_not') }}
      </p>
      <el-form
        ref="form"
        :inline="true"
        :model="form"
        :rules="rules"
        class="demo-form-inline"
      >
        <el-form-item
          :label="$t('support.chat.wysywyg.rows')"
          prop="rows"
        >
          <el-input
            v-model="form.rows"
            class="w-12"
            size="small"
            :placeholder="$t('support.chat.wysywyg.rows')"
          />
        </el-form-item>
        <el-form-item
          :label="$t('support.chat.wysywyg.cols')"
          prop="columns"
        >
          <el-input
            v-model="form.columns"
            class="w-12"
            size="small"
            :placeholder="$t('support.chat.wysywyg.cols')"
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer text-right"
      >
        <el-button
          size="small"
          @click="modalVisible = false"
        >
          {{ $t('actions.cancel') }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="setTables"
        >
          {{ $t('actions.set') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';

export default {
  name: 'SupportChatInput',

  components: {
    EditorContent,
  },

  props: {
    request: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      editor: null,
      empty: true,
      form: {
        columns: 3,
        rows: 3,
      },
      modalVisible: false,
      rules: {
        columns: [
          { required: true, message: 'required', trigger: 'blur' },
          { pattern: /1|2|3|4|5/, message: 'invalid', trigger: 'blur' },
        ],

        rows: [
          { required: true, message: 'required', trigger: 'blur' },
          { pattern: /1|2|3|4|5/, message: 'invalid', trigger: 'blur' },
        ],
      },
    };
  },

  mounted() {
    const self = this;
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3, 4, 5],
          },
        }),
        Link,
        Placeholder.configure({
          placeholder: self.$t('support.chat.type_your'),
        }),
        Table,
        TableRow,
        TableCell,
        TableHeader,
      ],

      onCreate({ editor }) {
        self.$emit('update-editor', editor);
      },

      onUpdate({ editor }) {
        self.empty = editor.isEmpty;

        self.$emit('update-input', {
          bodyAsPlain: editor.getText(),
          bodyAsHtml: editor.getHTML(),
          isEmpty: editor.isEmpty,
        });
      },
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },

  methods: {
    setLink() {
      const self = this;
      this.$prompt(self.$t('support.chat.valid_url'), self.$t('support.chat.set_url'), {
        confirmButtonText: self.$t('actions.ok'),
        cancelButtonText: self.$t('actions.cancel'),
        // eslint-disable-next-line no-useless-escape
        inputPattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g,
        inputErrorMessage: self.$t('support.chat.must_be'),
      }).then(({ value }) => {
        const previousUrl = this.editor.getAttributes('link').href;
        const url = value || previousUrl;

        // empty
        if (!url) {
          this.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run();

          return;
        }

        // update link
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url, target: '_blank' })
          .run();
      }).catch(() => {
        // do nothing
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run();
      });
    },

    setTables() {
      const self = this;
      this.$refs.form.validate((isValid) => {
        if (isValid) {
          const { rows, columns: cols } = self.form;
          self.editor.chain().focus().insertTable(
            { rows, cols },
          ).run();
          self.$refs.form.resetFields();
          self.modalVisible = false;
        }
      });
    },
  },
};
</script>
<style lang="scss">
  .chat-input {
    .ProseMirror {
      border-radius: .125rem;
      border-width: 1px;
      border-color: #dae1e7;
      width: 100%;
      height: 120px;
      padding: 10px;
      font-size: 12px;
      outline: 0px solid transparent;
      overflow-x: hidden;
      overflow-y: auto;

      > * + * {
        margin-top: 0.75em;
      }

      ul,
      ol {
        padding: 0 1rem;
      }

      blockquote {
        padding-left: 1rem;
        border-left: 3px solid rgba(#0D0D0D, 0.1);
      }

      a {
        color: #1f73b7;
      }

      code {
        font-size: 12px;
        padding: 0.25em;
        border-radius: 0.25em;
        background-color: #F8F9F9;
        border-color: #C2C8CC;
        color: #616161;
        box-decoration-break: clone;
      }

      pre {
        background: #F8F9F9;
        color: #616161;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;
        border-color: #C2C8CC;

        code {
          color: inherit;
          padding: 0;
          background: none;
          font-size: 0.8rem;
        }
      }

      hr {
        border-top: 1px solid #C2C8CC;
      }

      table, th, td {
        border: 1px solid #616161;
      }

      table td, table th {
        padding: 2px;
        min-width: 3em;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;
      }

      table tr:first-child {
        font-weight: 500;
      }
    }

    .ProseMirror p.is-editor-empty:first-child::before {
      content: attr(data-placeholder);
      float: left;
      color: #adb5bd;
      pointer-events: none;
      height: 0;
    }
  }

  .chat-input.is-empty .ProseMirror{
    border-color: #fcd9b6;
  }

  .chat-input.is-not-empty .ProseMirror{
    border-color: #dae1e7;
  }

</style>
