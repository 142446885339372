<template>
  <div>
    <div
      class="flex flex-col items-center justify-center"
    >
      <p class="mb-5 text-blue font-medium">
        {{ ticket.satisfaction_rating
          && !['good', 'bad'].includes(ticket.satisfaction_rating.score.toLowerCase())
          ? $t('support.conversation.how_would_rate')
          : $t('support.rating.would_you') }}
      </p>
      <div class="flex flex-row items-center justify-center">
        <span
          class="rounded-full h-6 w-6 bg-green flex items-center justify-center cursor-pointer hover:bg-green-light mr-2"
          @click="rating='good'"
        >
          <span class="material-icons-outlined text-white text-xs font-semibold">
            thumb_up
          </span>
        </span>
        <span
          class="text-grey-dark mr-10"
          :class="{'text-green': rating === 'good'}"
        >{{ $t('support.rating.satisfied') }} </span>
        <span
          class="rounded-full h-6 w-6 bg-red flex items-center justify-center cursor-pointer hover:bg-red-light
                  material-icons-outlined mr-2"
          @click="rating='bad'"
        >
          <span
            class="text-white text-xs font-semibold"
          >
            thumb_down
          </span>
        </span>
        <span
          class="text-grey-dark"
          :class="{'text-red': rating === 'bad'}"
        >{{ $t('support.rating.unsatisfied') }}</span>
      </div>
      <div class="w-4/5 mt-8">
        <p class="text-xs mb-2 text-grey-darker">
          {{ $t('support.rating.feedback') }}
        </p>
        <el-input
          v-model="comment"
          type="textarea"
          :rows="3"
        />
      </div>
    </div>
    <div
      slot="footer"
      class="dialog-footer text-right w-5/6 ml-3 pt-4"
    >
      <el-button
        size="small"
        class="mr-2"
        @click="handleBeforeClose"
      >
        {{ $t('actions.cancel') }}
      </el-button>
      <el-button
        size="small"
        type="primary"
        :loading="loading"
        :disabled="!rating"
        @click="$emit('submit', ticket, { rating, comment })"
      >
        {{ $t('actions.submit') }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SupportTicketRating',

  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      rating: '',
      comment: '',
    };
  },

  created() {
    // Populate rating and comment if existing
    if (Object.keys(this.ticket).length && this.ticket.satisfaction_rating) {
      this.setTicketRating();
    } else {
      // In case the ticket prop is empty at this time,
      // watch the prop once and populate the rating and comment if existing
      this.unwatchTicketProp = this.$watch('ticket', (newVal) => {
        if (newVal) {
          this.unwatchTicketProp();
          this.setTicketRating();
        }
      });
    }
  },

  methods: {
    handleBeforeClose() {
      if (Object.keys(this.ticket).length) {
        this.setTicketRating();
      } else {
        this.rating = '';
        this.comment = '';
      }
      this.$emit('close');
    },

    setTicketRating() {
      if (Object.keys(this.ticket).length) {
        if (this.ticket.satisfaction_rating) {
          if (this.ticket.satisfaction_rating.score && ['good', 'bad'].includes(this.ticket.satisfaction_rating.score.toLowerCase())) {
            this.rating = this.ticket.satisfaction_rating.score.toLowerCase();
          }

          if (this.ticket.satisfaction_rating.comment) {
            this.comment = this.ticket.satisfaction_rating.comment;
          }
        }
      }
    },
  },
};
</script>
