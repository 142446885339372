//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  name: 'App',

  data() {
    return {};
  },

  created() {
    // if (window.location.pathname.match(/\/articles|\/article/g)) {
    //   window.location = '/maintenance';
    // }

    this.getUser()
      .catch((e) => {
        this.$showError(this, e);
      });

    this.getZendeskUser()
      .catch((e) => {
        this.$showError(this, e);
      });
  },

  methods: {
    ...mapActions({
      getUser: 'user/getUser',
      getZendeskUser: 'user/getZendeskUser',
    }),
  },
};
