//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapMutations } from 'vuex';
import utils from '../utils';
import timeFormatter from '../mixins/timeFormatter';

export default {
  name: 'SupportArticle',

  components: {

  },

  mixins: [timeFormatter],

  data() {
    return {
      loading: false,
      search: '',
    };
  },

  computed: {
    ...mapGetters({
      article: 'articles/getArticle',
      locales: 'articles/getLocales',
      author: 'articles/getAuthor',
      user: 'user/getUser',
      getTz: 'user/getTz',
    }),
  },

  created() {
    this.search = '';
    this.setArticle({});
    this.fetchLocales();
    this.fetchData();
  },

  methods: {
    ...mapActions({
      getArticles: 'articles/getArticles',
      getArticle: 'articles/getArticle',
      getLocales: 'articles/getLocales',
    }),

    ...mapMutations({
      setArticle: 'articles/SET_ARTICLE',
    }),

    onSearch() {
      this.$router.replace({ path: '/articles', query: { search_term: this.search.trim() } });
    },

    async fetchLocales() {
      try {
        // this.loading = true;

        await this.getLocales();
      } catch (e) {
        this.$showError(this, e);
      } finally {
        // this.loading = false;
      }
    },

    async fetchData() {
      try {
        this.loading = true;
        const locale = this.locales.length ? this.locales[0].locale : 'en-US';

        await this.getArticle({ id: this.$route.params.id, locale });
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
      }
    },

    navigate(config) {
      this.$router.push(config);
    },

    sanitizeHtml(content) {
      return utils.sanitizeHtml(content);
    },
  },
};
