/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    chatAuth: {},
  },

  getters: {
    getChatAuth: st => st.chatAuth,
  },

  mutations: {
    SET_CHAT_AUTH: (st, payload) => { st.chatAuth = payload; },
  },

  actions,
};
