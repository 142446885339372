import Vue from 'vue';
import Vuex from 'vuex';
// import { Message } from 'element-ui';

import user from './user';
import tickets from './tickets';
import articles from './articles';
import chat from './chat';
import senderId from './sender-id';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  state: {
    errors: [],
  },

  getters: {},

  mutations: {
    API_ERROR(state, payload) {
      // eslint-disable-next-line no-unused-vars
      const error = payload
        && payload.body
        && payload.body.message ? payload.body.message : payload;
      // bugsnagClient.notify(error);
      state.errors.push(payload); // eslint-disable-line no-param-reassign

      // Message.error(error.message);
    },
  },

  actions: {},

  modules: {
    user,
    tickets,
    articles,
    chat,
    senderId,
  },

  strict: debug,
});

export default store;
