//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import utils from '../../utils';

export default {
  name: 'SupportFileThumbnail',

  props: {
    file: {
      type: String,
      default: '',
    },
  },

  data() {
    return {

    };
  },

  methods: {
    ...mapActions({
      saveFile: 'tickets/downloadFile',
    }),
    getFileIcon(filename) {
      return utils.getFileIcon(filename);
    },

    isImage(file) {
      return file.file_name.match(/\.jpg|\.jpeg|\.png|\.gif|\.svg/g);
    },

    downloadFile(ev) {
      ev.preventDefault();
      this.saveFile({ ...this.file });
    },
  },
};
