/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    locales: [],
    articles: {},
    article: {},
    author: {},
    authors: [],
  },

  getters: {
    getArticles: st => st.articles,
    getArticle: st => st.article,
    getLocales: st => st.locales,
    getAuthors: st => st.authors,
    getAuthor: st => st.author,
  },

  mutations: {
    SET_ARTICLES: (st, payload) => { st.articles = payload; },

    SET_ARTICLE: (st, payload) => { st.article = payload; },

    SET_LOCALES: (st, payload) => { st.locales = payload; },

    SET_AUTHORS: (st, payload) => { st.authors = payload; },

    SET_AUTHOR: (st, payload) => { st.author = payload; },
  },

  actions,
};
