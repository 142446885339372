import Layout from './views/Layout.vue';
import Home from './views/Home.vue';
import Articles from './views/Articles.vue';
import Article from './views/Article.vue';
import Tickets from './views/Tickets.vue';
import CreateTickets from './views/CreateTicket.vue';
import TicketConversation from './views/TicketConversation.vue';

export default [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
      },

      {
        path: '/articles',
        name: 'articles',
        component: Articles,
      },

      {
        path: '/articles/:id',
        name: 'article',
        component: Article,
      },

      {
        path: '/tickets',
        name: 'tickets',
        component: Tickets,
      },

      {
        path: '/tickets/create',
        name: 'create-ticket',
        component: CreateTickets,
      },

      {
        path: '/tickets/:id/conversation',
        name: 'ticket-conversation',
        component: TicketConversation,
      },
    ],
  },
];
