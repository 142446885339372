<template>
  <a
    href="/"
    class="block no-underline shadow"
    @click="downloadFile"
  >
    <el-card :body-style="{ padding: '0px' }">
      <div
        v-if="isImage(file)"
        class="h-8"
      >
        <img
          :src="file.content_url"
          :alt="file.file_name"
          crossorigin="Anonymous"
          class="image-thumbnail h-8"
        >
      </div>
      <div
        v-else
        class="text-center text-blue-light flex flex-col justify-center h-8"
      >
        <span

          class="material-icons-outlined"
        >
          {{ getFileIcon(file.file_name) }}
        </span>
      </div>
      <div
        class="p-1 bg-grey-lightest truncate hover:text-blue"
      >
        <span :style="{fontSize: '8px'}">{{ file.file_name }}</span>
      </div>
    </el-card>
  </a>
</template>

<script>
import { mapActions } from 'vuex';
import utils from '../../utils';

export default {
  name: 'SupportFileThumbnail',

  props: {
    file: {
      type: String,
      default: '',
    },
  },

  data() {
    return {

    };
  },

  methods: {
    ...mapActions({
      saveFile: 'tickets/downloadFile',
    }),
    getFileIcon(filename) {
      return utils.getFileIcon(filename);
    },

    isImage(file) {
      return file.file_name.match(/\.jpg|\.jpeg|\.png|\.gif|\.svg/g);
    },

    downloadFile(ev) {
      ev.preventDefault();
      this.saveFile({ ...this.file });
    },
  },
};
</script>
<style scoped>
 .image-thumbnail {
    width: 100%;
    object-fit: cover;
 }
</style>
