//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { handlePackageLimit } from '../../common/handle-package-limit';

export default {
  name: 'SupportOverview',

  components: {

  },

  data() {
    return {
      isScriptLoaded: false,
      isChatWindowHide: true,
      isShowChat: false,
    };
  },

  computed: {
    ...mapGetters({
      chatAuth: 'chat/getChatAuth',
      user: 'user/getUser',
      account: 'user/getAccount',
    }),
  },

  created() {

  },

  mounted() {
    if (handlePackageLimit('cp-support')) this.authenticate();
  },

  beforeDestroy() {
    if (window.zE) {
      window.zE('webWidget', 'close');
      window.zE('webWidget', 'hide');
    }
    this.isShowChat = false;
  },

  methods: {
    ...mapActions({
      getAuth: 'chat/authenticate',
      getAccount: 'user/getAccount',
    }),
    attachScript() {
      return new Promise((resolve) => {
        const script = document.createElement('script');
        script.setAttribute('src', 'https://static.zdassets.com/ekr/snippet.js?key=8d7301b1-3f01-46e7-98ae-36fcb8c33098');
        script.setAttribute('id', 'ze-snippet');
        script.async = true;

        script.onload = () => {
          this.isScriptLoaded = true;
          resolve();
        };

        document.head.appendChild(script);
      });
    },

    async loadScript() {
      try {
        await this.attachScript();
      } catch (e) {
        this.$showError(this, e);
      }
    },

    async authenticate() {
      try {
        await this.getAuth();
        await this.getAccount({
          uid: this.user.UserId,
          aid: this.user.AccountUid,
        });

        const { CustomerCategory: customerCategory } = this.account;

        if (customerCategory && customerCategory === 'VIP') {
          this.isShowChat = true;
          // set first the config
          this.setChatConfig();

          // load the script and will look up to config
          this.loadScript();

          setTimeout(() => {
            this.scripLoaded = true;
            // this.hideOriginalButton();
            this.listenToEvents();
          }, 500);
        }
      } catch (e) {
        this.$showError(this, e);
      }
    },

    setChatConfig() {
      const { token } = this.chatAuth;

      window.zESettings = {
        webWidget: {
          // color: {
          //   theme: '#CB2232',
          //   launcher: '#CB2232', // This will also update the badge
          //   launcherText: '#FFFFFF',
          //   button: '#CB2232',
          // },

          answerBot: {
            suppress: false,
          },

          contactForm: {
            ticketForms: [
              {
                id: 1900000236028,
                fields: [
                  {
                    id: 'description',
                    prefill: {
                      '*': '-',
                    },
                  },

                  {
                    id: 'email',
                    prefill: {
                      '*': this.user ? this.user.Email : '',
                    },
                  },

                  {
                    id: 'subject',
                    prefill: {
                      '*': `8x8 Connect Form (${this.user ? this.user.AccountId : ''})`,
                    },
                  },

                  {
                    id: 900011192783,
                    prefill: {
                      '*': this.user ? this.user.AccountId : '',
                    },
                  },
                ],
              },
            ],
          },

          chat: {
            suppress: false,
            prechatForm: {
              greeting: {
                '*': 'Please fill out the form below to chat with us',
              },
            },

            offlineForm: {
              greeting: {
                '*': 'Sorry, we aren\'t online right now, please complete this form and we will get back to you',
              },
            },

            departments: {
              enabled: [],
            },
          },

          authenticate: {
            chat: {
              jwtFn(callback) {
                callback(token);
              },
            },
          },

          // launcher: {
          //   suppress: false,
          //   chatLabel: {
          //     '*': 'Chat now',
          //   },
          // },

          helpCenter: {
            suppress: false,
            chatButton: {
              '*': 'Chat',
            },
          },

          contactOptions: {
            enabled: true,
            chatLabelOnline: { '*': 'Chat' },
            chatLabelOffline: { '*': 'Chat is unavailable' },
          },
        },
      };
    },

    toggleLiveChat() {
      if (window.zE) {
        if (this.isChatWindowHide) {
          window.zE('webWidget', 'show');
          window.zE('webWidget', 'open');
        } else {
          window.zE('webWidget', 'close');
        }
      }
    },

    hideOriginalButton() {
      if (window.zE) {
        window.zE('webWidget', 'hide');
      }
    },

    listenToEvents() {
      if (window.zE) {
        window.zE(() => {
          let sl = localStorage.getItem('SELECTED_LOCALE');

          if (sl === 'en' || !sl) {
            sl = 'en-us';
          }

          window.zE.setLocale(sl.toLowerCase());
        });

        window.zE('webWidget:on', 'close', () => {
          // window.zE('webWidget', 'hide');
          this.isChatWindowHide = true;
        });

        window.zE('webWidget:on', 'open', () => {
          this.isChatWindowHide = false;
        });
      }
    },
  },
};
