<template>
  <div class="mb-20">
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      class="mt-5"
    >
      <el-breadcrumb-item :key="1">
        <span
          class="text-blue cursor-pointer"
          @click="navigate({ path: '/'})"
        >
          {{ $t('support.title') }}
        </span>
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :key="2"
        :style="{width: '250px'}"
        class="truncate"
      >
        <span class="text-grey">{{ $t('support.main.ticket_history.title') }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="w-full mt-5">
      <h1 class="text-xl text-color font-semibold page-title mb-5">
        {{ $t('support.main.ticket_history.title') }}
      </h1>
      <p class="text-sm text-grey-dark block">
        {{ $t('support.main.ticket_history.page_desc') }}
      </p>
      <div class="mt-5 w-3/5">
        <el-alert
          type="warning"
          :closable="Boolean(true)"
        >
          <span slot="title">
            {{ $t('support.tickets.warning') }} {{ $t('support.tickets.do_click') }}
            <span
              class="text-blue cursor-pointer"
              @click="fetchData()"
            >{{ $t('actions.refresh').toLowerCase() }}</span>
            {{ $t('support.tickets.see_new_tickets') }}
          </span>
        </el-alert>
      </div>
      <div class="flex mt-5">
        <div class="w-1/2">
          <el-button
            type="primary"
            size="small"
            @click="navigate({ path: '/tickets/create'})"
          >
            {{ $t('support.tickets.create_ticket') }}
          </el-button>
        </div>
        <div class="w-1/2 text-right">
          <el-button
            type="primary"
            size="small"
            :loading="exporting"
            @click="exportTickets()"
          >
            {{ $t('actions.export') }}
          </el-button>
        </div>
      </div>

      <div class="mt-5">
        <div class="flex mb-5">
          <div class="flex-1">
            <el-select
              v-if="user.AccessLevel === 'A'"
              v-model="adminFilter"
              :disabled="loading"
              class="w-1/3 mr-2"
              size="mini"
              @change="onAdminFilterChange"
            >
              <el-option
                v-for="item in adminFilters"
                :key="item.name"
                :label="item.text"
                :value="item.name"
              />
            </el-select>
            <el-select
              v-model="status"
              :disabled="loading"
              class="w-1/3"
              size="mini"
              @change="onStatusChange"
            >
              <el-option
                v-for="item in statuses"
                :key="item.name"
                :label="item.text"
                :value="item.name"
              />
            </el-select>
          </div>
          <div class="flex-1 text-right">
            <el-input
              v-model="search"
              :disabled="loading"
              class="w-3/5"
              size="mini"
              :placeholder="$t('support.tickets.search_for_ticket')"
              @keyup.native.enter="onSearch"
            />
          </div>
        </div>
        <div v-loading="loading">
          <el-table
            ref="table"
            size="small"
            class="w-full table"
            :data="tickets.results"
            :sort-method="undefined"
            :default-sort="defaultSort"
            row-class-name="cursor-pointer"
            @sort-change="onSortChange"
            @row-click="onRowClick"
          >
            <el-table-column
              v-slot="scope"
              :label="$t('column_labels.rating')"
              :width="80"
            >
              <span
                v-if="scope.row.satisfaction_rating && scope.row.satisfaction_rating.score && scope.row.satisfaction_rating.score.toLowerCase() === 'good'"
                class="rounded-full h-6 w-6 flex items-center justify-center bg-green"
              >
                <span class="material-icons-outlined text-white text-xs font-semibold">
                  thumb_up
                </span>
              </span>

              <span
                v-else-if="scope.row.satisfaction_rating && scope.row.satisfaction_rating.score && scope.row.satisfaction_rating.score.toLowerCase() === 'bad'"
                class="rounded-full h-6 w-6 flex items-center justify-center bg-red"
              >
                <span class="material-icons-outlined text-white text-xs font-semibold">
                  thumb_down
                </span>
              </span>
              <span v-else>-</span>
            </el-table-column>
            <el-table-column
              v-slot="scope"
              :width="100"
              :label="$t('column_labels.ticket_id')"
            >
              <div class="text-xs truncate">
                {{ scope.row.id }}
              </div>
            </el-table-column>
            <el-table-column
              v-slot="scope"
              :label="$t('column_labels.subject')"
            >
              <div class="text-xs truncate">
                {{ transformSubject(scope.row.subject) }}
              </div>
            </el-table-column>
            <el-table-column
              sortable="custom"
              prop="created_at"
              :label="getDateHeader(getTz.timeZone, $t('column_labels.ticket_created'))"
            >
              <template slot-scope="scope">
                <span class="text-xs">
                  {{ timeFromNow(scope.row.created_at, getTz.timeZoneOffset) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              sortable="custom"
              prop="updated_at"
              :label="getDateHeader(getTz.timeZone, $t('column_labels.ticket_updated'))"
            >
              <template slot-scope="scope">
                <span class="text-xs">
                  {{ timeFromNow(scope.row.updated_at, getTz.timeZoneOffset) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('column_labels.status')"
              :width="150"
            >
              <template slot-scope="scope">
                <span class="text-xs">
                  <el-tag
                    :type="getStatus(scope.row.status).statusColor"
                    size="mini"
                    class="text-xs"
                  >{{ getStatus(scope.row.status).status.toUpperCase() }}</el-tag>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('column_labels.requester')"
              :width="150"
            >
              <template slot-scope="scope">
                <span class="text-xs">
                  {{ getRequester(scope.row) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :width="150">
              <template
                slot="header"
                slot-scope="scope"
              >
                <div class="flex">
                  <!-- <div class="flex-1 pt-1">
                    Rate
                  </div> -->
                  <div class="flex-1 text-right">
                    <el-tooltip
                      effect="dark"
                      :content="$t('actions.refresh')"
                      placement="top-start"
                      class="text-xs"
                    >
                      <span
                        class="material-icons-outlined align-middle text-xl cursor-pointer text-white hover:text-blue"
                        @click="fetchData()"
                      >refresh</span>
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <span
                  v-if="showRateAction(scope.row)"
                  class="ml-4 text-red text-xs cursor-pointer rate"
                  @click="openRatingModal(scope.row)"
                >
                  {{
                    scope.row.satisfaction_rating
                      && scope.row.satisfaction_rating.score
                      && ['bad', 'good'].includes(scope.row.satisfaction_rating.score.toLowerCase())
                      ? $t('support.tickets.change_the_rating') : $t('support.tickets.rate_the_ticket')
                  }}
                </span>
              </template>
            </el-table-column>
          </el-table>
          <div
            v-if="tickets.count"
            class="text-right mt-5"
          >
            <el-pagination
              :current-page.sync="filters.page"
              :page-sizes="[5, 10, 20, 30, 40]"
              :page-size="filters.per_page"
              layout="total, sizes, prev, pager, next, jumper"
              :total="tickets.count"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="ratingModalVisible"
      ref="ratingModal"
      title=""
      :visible.sync="ratingModalVisible"
      :close-on-click-modal="false"
      :show-close="false"
      width="40%"
      @close="closeRatingModal"
    >
      <RatingForm
        :ticket="selectedTicket"
        :loading="loading"
        @close="closeRatingModal"
        @submit="submitRating"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import moment from 'moment';
import timeFormatter from '../mixins/timeFormatter';
import utils from '../utils';

import RatingForm from './partials/TicketRating.vue';

export default {
  name: 'SupportTicketHistory',

  components: {
    RatingForm,
  },

  mixins: [timeFormatter],

  data() {
    const defaultPerPage = 10;

    const self = this;

    return {
      defaultPerPage,
      ratingModalVisible: false,
      selectedTicket: {},
      loading: false,
      exporting: false,
      search: '',
      status: 'all',
      adminFilter: 'all',
      defaultSort: { prop: 'created_at', order: 'descending' },
      statuses: [{
        name: 'all',
        text: self.$t('support.tickets.statuses.all_statuses').toUpperCase(),
      }, {
        name: 'open',
        text: self.$t('support.tickets.statuses.open').toUpperCase(),
      },
      {
        name: 'solved',
        text: self.$t('support.tickets.statuses.solved').toUpperCase(),
      },
      {
        name: 'closed',
        text: self.$t('support.tickets.statuses.closed').toUpperCase(),
      }, {
        name: 'pending',
        text: self.$t('support.tickets.statuses.awaiting_your_reply').toUpperCase(),
      }],

      adminFilters: [{
        name: 'all',
        text: self.$t('support.tickets.filters.all_tickets').toUpperCase(),
      }, {
        name: 'mine',
        text: self.$t('support.tickets.filters.my_tickets').toUpperCase(),
      }],
      filters: {
        sort_by: 'created_at',
        sort_order: 'desc',
        search_term: '',
        page: 1,
        status: '',
        filter: '',
        per_page: defaultPerPage,
      },
    };
  },

  computed: {
    ...mapGetters({
      tickets: 'tickets/getRequests',
      user: 'user/getUser',
      zendeskUser: 'user/getZendeskUser',
      getTz: 'user/getTz',
    }),
  },

  watch: {
    filters: {
      handler() {
        this.fetchData();
      },

      deep: true,
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    ...mapActions({
      getRequests: 'tickets/getRequests',
      makeRating: 'tickets/addRating',
    }),

    ...mapMutations({
      setTickets: 'tickets/SET_REQUESTS',
    }),

    closeRatingModal() {
      this.ratingModalVisible = false;
      this.selectedTicket = {};
    },

    openRatingModal(ticket) {
      this.selectedTicket = ticket;
      this.ratingModalVisible = true;
    },

    async submitRating(ticket, payload) {
      try {
        this.loading = true;
        await this.makeRating({ id: ticket.id, ...payload });

        const { results } = this.tickets;
        const { comment, rating } = payload;

        results.map((v) => {
          if (v.id === ticket.id) {
            // eslint-disable-next-line no-param-reassign
            v.satisfaction_rating.score = rating;
            // eslint-disable-next-line no-param-reassign
            v.satisfaction_rating.comment = comment;
          }
          return v;
        });

        this.setTickets({ ...this.tickets, results });

        this.closeRatingModal();
        this.$message.success(this.$t('support.tickets.thank_you'));
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
      }
    },

    async fetchData() {
      try {
        if (this.filters.export) {
          this.exporting = true;
        } else {
          this.loading = true;
        }
        await this.getRequests({ filters: { ...this.filters } });

        if (this.filters.noticket) {
          this.$message.success(this.$t('chat_apps.reports.export_modal[5]'));
        } else if (this.filters.export) {
          this.$message.success(this.$t('chat_apps.reports.export_modal[1]'));
        }
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
        this.exporting = false;
      }
    },

    transformSubject(subj) {
      return utils.getSubject(subj);
    },

    getStatus(status) {
      const st = utils.getStatus(status);

      if (st.status === 'awaiting your reply') {
        st.status = this.$t('support.tickets.statuses.awaiting_your_reply');
      } else {
        st.status = this.$t(`support.tickets.statuses.${st.status.toLowerCase()}`);
      }

      return st;
    },

    showRateAction(row) {
      let show = false;
      const given = moment(new Date(row.updated_at));
      const current = moment(new Date());

      const diff = current.diff(given, 'days');

      if (row.requester_id === this.zendeskUser.id && ['solved'].includes(row.status.toLowerCase()) && diff <= 96) {
        show = true;
      }

      return show;
    },

    getLocale() {
      return utils.getLocale();
    },

    getRequester(row) {
      const requester = this.tickets.users.find(v => v.id === row.requester_id);

      if (requester) {
        return !requester.name || requester.name === 'null null' ? 'Customer' : requester.name;
      }

      return '-';
    },

    exportTickets() {
      const noticket = this.tickets.results?.length < 1;
      this.filters = {
        ...this.filters,
        export: true,
        noticket,
      };
    },

    onSortChange(scope) {
      const sortBy = scope.prop;
      const sortOrder = scope.order === 'ascending' ? 'asc' : 'desc';

      delete this.filters.export;

      this.filters = {
        ...this.filters,
        sort_by: sortBy,
        sort_order: sortOrder,
        per_page: this.defaultPerPage,
        page: 1,
      };
    },

    onRowClick(row, table, ev) {
      if (ev.target.className.match(/rate/i)) {
        ev.preventDefault();
        ev.stopPropagation();
      } else {
        this.navigate({ path: `/tickets/${row.id}/conversation` });
      }
    },

    onSearch() {
      delete this.filters.export;

      this.filters = {
        ...this.filters,
        search_term: this.search.trim(),
        per_page: this.defaultPerPage,
        page: 1,
      };
    },

    onStatusChange() {
      let st = this.status;


      if (st === 'all') {
        st = '';
      }

      delete this.filters.export;

      this.filters = {
        ...this.filters,
        status: st,
        per_page: this.defaultPerPage,
        page: 1,
      };
    },

    onAdminFilterChange() {
      let filter = this.adminFilter;


      if (filter === 'all') {
        filter = '';
      }

      delete this.filters.export;

      this.filters = {
        ...this.filters, filter, per_page: this.defaultPerPage, page: 1,
      };
    },

    handleSizeChange(size) {
      delete this.filters.export;
      this.filters = {
        ...this.filters, per_page: size, page: 1,
      };
    },

    handleCurrentChange(page) {
      delete this.filters.export;
      this.filters = { ...this.filters, page };
    },

    navigate(config) {
      this.$router.push(config);
    },
  },
};
</script>
