import http from '../../../../utils/http';


export default {
  getSenderIdData: async ({ commit }, payload = {}) => {
    const { id } = payload;

    if (!id) return Promise.reject(new Error('Cancelled'));

    try {
      const response = await http.v2.get(`/sender-id/registration/tickets/${id}`);
      const { data } = response;

      commit('SET_SENDER_ID_DATA', data);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
