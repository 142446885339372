//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import timeFormatter from '../mixins/timeFormatter';
import utils from '../utils';

import UserDetail from './partials/UserDetail.vue';

export default {
  name: 'SupportArticles',

  components: {
    UserDetail,
  },

  mixins: [timeFormatter],

  data() {
    const defaultPerPage = 25;

    return {
      loading: false,
      defaultPerPage,
      search: '',
      filters: {
        sort_by: 'updated_at',
        sort_order: 'desc',
        search_term: '',
        page: 1,
        per_page: defaultPerPage,
        locale: 'en-US',
      },
    };
  },

  computed: {
    ...mapGetters({
      articles: 'articles/getArticles',
      article: 'articles/getArticle',
      locales: 'articles/getLocales',
      author: 'articles/getAuthor',
      user: 'user/getUser',
      getTz: 'user/getTz',
    }),
  },

  watch: {
    filters: {
      handler() {
        this.fetchData();
      },

      deep: true,
    },

    $route: {
      handler(current) {
        this.clearData();
        let searchTerm = '';

        if (current.query.search_term) {
          searchTerm = this.$route.query.search_term;
          this.search = searchTerm;

          this.filters.search_term = searchTerm;
        } else {
          this.search = '';
          this.fetchData();
        }
      },
    },
  },

  created() {
    this.clearData();
    let searchTerm = '';

    this.fetchLocales();

    if (this.$route.query.search_term) {
      searchTerm = this.$route.query.search_term;
      this.search = searchTerm;

      this.filters.search_term = searchTerm;
    } else {
      this.search = '';
      this.fetchData();
    }
  },

  methods: {
    ...mapActions({
      getArticles: 'articles/getArticles',
      getArticle: 'articles/getArticle',
      getLocales: 'articles/getLocales',
      getAuthor: 'articles/getAuthor',
    }),

    onSearch() {
      this.navigate({ path: '/articles', query: { search_term: this.search.trim() } });
    },

    getSideLoadedAuthor(userId) {
      return this.articles.users.find(u => u.id === userId);
    },

    async fetchLocales() {
      try {
        // this.loading = true;

        await this.getLocales();
      } catch (e) {
        this.$showError(this, e);
      } finally {
        // this.loading = false;
      }
    },

    async fetchData() {
      try {
        this.loading = true;
        let locale = 'en-us';

        if (this.locales.length) {
          // eslint-disable-next-line prefer-destructuring
          locale = this.locales[0].locale;
        }

        const f = { ...this.filters };

        if (f.search_term) {
          f.sort_by = '';
          f.sort_order = '';
        }

        await this.getArticles({ filters: { ...f, ...{ locale } } });
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
      }
    },

    navigate(config) {
      this.$router.push(config);
    },

    sanitizeHtml(content) {
      return utils.sanitizeHtml(content);
    },

    handleSizeChange(size) {
      this.filters = { ...this.filters, per_page: size, page: 1 };
    },

    handleCurrentChange(page) {
      this.filters = { ...this.filters, page };
    },

    clearData() {
      const filters = {
        sort_by: 'updated_at',
        sort_order: 'desc',
        search_term: '',
        page: 1,
        per_page: this.defaultPerPage,
        locale: 'en-US',
      };

      this.filters = { ...filters };
    },
  },
};
