//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapMutations } from 'vuex';

import moment from 'moment';
import timeFormatter from '../mixins/timeFormatter';
import utils from '../utils';

import RatingForm from './partials/TicketRating.vue';

export default {
  name: 'SupportTicketHistory',

  components: {
    RatingForm,
  },

  mixins: [timeFormatter],

  data() {
    const defaultPerPage = 10;

    const self = this;

    return {
      defaultPerPage,
      ratingModalVisible: false,
      selectedTicket: {},
      loading: false,
      exporting: false,
      search: '',
      status: 'all',
      adminFilter: 'all',
      defaultSort: { prop: 'created_at', order: 'descending' },
      statuses: [{
        name: 'all',
        text: self.$t('support.tickets.statuses.all_statuses').toUpperCase(),
      }, {
        name: 'open',
        text: self.$t('support.tickets.statuses.open').toUpperCase(),
      },
      {
        name: 'solved',
        text: self.$t('support.tickets.statuses.solved').toUpperCase(),
      },
      {
        name: 'closed',
        text: self.$t('support.tickets.statuses.closed').toUpperCase(),
      }, {
        name: 'pending',
        text: self.$t('support.tickets.statuses.awaiting_your_reply').toUpperCase(),
      }],

      adminFilters: [{
        name: 'all',
        text: self.$t('support.tickets.filters.all_tickets').toUpperCase(),
      }, {
        name: 'mine',
        text: self.$t('support.tickets.filters.my_tickets').toUpperCase(),
      }],
      filters: {
        sort_by: 'created_at',
        sort_order: 'desc',
        search_term: '',
        page: 1,
        status: '',
        filter: '',
        per_page: defaultPerPage,
      },
    };
  },

  computed: {
    ...mapGetters({
      tickets: 'tickets/getRequests',
      user: 'user/getUser',
      zendeskUser: 'user/getZendeskUser',
      getTz: 'user/getTz',
    }),
  },

  watch: {
    filters: {
      handler() {
        this.fetchData();
      },

      deep: true,
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    ...mapActions({
      getRequests: 'tickets/getRequests',
      makeRating: 'tickets/addRating',
    }),

    ...mapMutations({
      setTickets: 'tickets/SET_REQUESTS',
    }),

    closeRatingModal() {
      this.ratingModalVisible = false;
      this.selectedTicket = {};
    },

    openRatingModal(ticket) {
      this.selectedTicket = ticket;
      this.ratingModalVisible = true;
    },

    async submitRating(ticket, payload) {
      try {
        this.loading = true;
        await this.makeRating({ id: ticket.id, ...payload });

        const { results } = this.tickets;
        const { comment, rating } = payload;

        results.map((v) => {
          if (v.id === ticket.id) {
            // eslint-disable-next-line no-param-reassign
            v.satisfaction_rating.score = rating;
            // eslint-disable-next-line no-param-reassign
            v.satisfaction_rating.comment = comment;
          }
          return v;
        });

        this.setTickets({ ...this.tickets, results });

        this.closeRatingModal();
        this.$message.success(this.$t('support.tickets.thank_you'));
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
      }
    },

    async fetchData() {
      try {
        if (this.filters.export) {
          this.exporting = true;
        } else {
          this.loading = true;
        }
        await this.getRequests({ filters: { ...this.filters } });

        if (this.filters.noticket) {
          this.$message.success(this.$t('chat_apps.reports.export_modal[5]'));
        } else if (this.filters.export) {
          this.$message.success(this.$t('chat_apps.reports.export_modal[1]'));
        }
      } catch (e) {
        this.$showError(this, e);
      } finally {
        this.loading = false;
        this.exporting = false;
      }
    },

    transformSubject(subj) {
      return utils.getSubject(subj);
    },

    getStatus(status) {
      const st = utils.getStatus(status);

      if (st.status === 'awaiting your reply') {
        st.status = this.$t('support.tickets.statuses.awaiting_your_reply');
      } else {
        st.status = this.$t(`support.tickets.statuses.${st.status.toLowerCase()}`);
      }

      return st;
    },

    showRateAction(row) {
      let show = false;
      const given = moment(new Date(row.updated_at));
      const current = moment(new Date());

      const diff = current.diff(given, 'days');

      if (row.requester_id === this.zendeskUser.id && ['solved'].includes(row.status.toLowerCase()) && diff <= 96) {
        show = true;
      }

      return show;
    },

    getLocale() {
      return utils.getLocale();
    },

    getRequester(row) {
      const requester = this.tickets.users.find(v => v.id === row.requester_id);

      if (requester) {
        return !requester.name || requester.name === 'null null' ? 'Customer' : requester.name;
      }

      return '-';
    },

    exportTickets() {
      const noticket = this.tickets.results?.length < 1;
      this.filters = {
        ...this.filters,
        export: true,
        noticket,
      };
    },

    onSortChange(scope) {
      const sortBy = scope.prop;
      const sortOrder = scope.order === 'ascending' ? 'asc' : 'desc';

      delete this.filters.export;

      this.filters = {
        ...this.filters,
        sort_by: sortBy,
        sort_order: sortOrder,
        per_page: this.defaultPerPage,
        page: 1,
      };
    },

    onRowClick(row, table, ev) {
      if (ev.target.className.match(/rate/i)) {
        ev.preventDefault();
        ev.stopPropagation();
      } else {
        this.navigate({ path: `/tickets/${row.id}/conversation` });
      }
    },

    onSearch() {
      delete this.filters.export;

      this.filters = {
        ...this.filters,
        search_term: this.search.trim(),
        per_page: this.defaultPerPage,
        page: 1,
      };
    },

    onStatusChange() {
      let st = this.status;


      if (st === 'all') {
        st = '';
      }

      delete this.filters.export;

      this.filters = {
        ...this.filters,
        status: st,
        per_page: this.defaultPerPage,
        page: 1,
      };
    },

    onAdminFilterChange() {
      let filter = this.adminFilter;


      if (filter === 'all') {
        filter = '';
      }

      delete this.filters.export;

      this.filters = {
        ...this.filters, filter, per_page: this.defaultPerPage, page: 1,
      };
    },

    handleSizeChange(size) {
      delete this.filters.export;
      this.filters = {
        ...this.filters, per_page: size, page: 1,
      };
    },

    handleCurrentChange(page) {
      delete this.filters.export;
      this.filters = { ...this.filters, page };
    },

    navigate(config) {
      this.$router.push(config);
    },
  },
};
