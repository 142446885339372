import http from '../../../../utils/http';

export default {
  getUser({ commit }) {
    try {
      const cpv3User = localStorage.getItem('CPV3_User') || {};

      const userData = JSON.parse(cpv3User);
      const token = localStorage.getItem('WWW-Authenticate');
      const user = { ...userData, Token: token };

      commit('SET_USER', { user });
      commit('SET_TIMEZONE', { ...user });

      return user;
    } catch (err) {
      commit('API_ERROR', err, { root: true });

      return err;
    }
  },

  getSubaccounts: async ({ commit }, payload) => {
    const { accountUid } = payload;
    try {
      const { data } = await http.v1.get(`accounts/${accountUid}/sub-accounts`);

      commit('SET_SUBACCOUNTS', data[0]);
      return data[0];
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getZendeskUser: async ({ commit }) => {
    try {
      const { data } = await http.v2.get('support/user');

      commit('SET_ZENDESK_USER', data);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  getZendeskUserById: async ({ commit }, payload) => {
    const { id } = payload;
    try {
      const { data } = await http.v2.get(`support/user/${id}`);

      commit('SET_ZENDESK_REQUESTER', data.user);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getAccount({ commit }, payload) {
    try {
      const { data } = await http.v1.get(`accounts/${payload.uid}/${payload.aid}`);
      commit('SET_USER_ACCOUNT', data);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
