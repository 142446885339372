//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SupportTicketRating',

  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      rating: '',
      comment: '',
    };
  },

  created() {
    // Populate rating and comment if existing
    if (Object.keys(this.ticket).length && this.ticket.satisfaction_rating) {
      this.setTicketRating();
    } else {
      // In case the ticket prop is empty at this time,
      // watch the prop once and populate the rating and comment if existing
      this.unwatchTicketProp = this.$watch('ticket', (newVal) => {
        if (newVal) {
          this.unwatchTicketProp();
          this.setTicketRating();
        }
      });
    }
  },

  methods: {
    handleBeforeClose() {
      if (Object.keys(this.ticket).length) {
        this.setTicketRating();
      } else {
        this.rating = '';
        this.comment = '';
      }
      this.$emit('close');
    },

    setTicketRating() {
      if (Object.keys(this.ticket).length) {
        if (this.ticket.satisfaction_rating) {
          if (this.ticket.satisfaction_rating.score && ['good', 'bad'].includes(this.ticket.satisfaction_rating.score.toLowerCase())) {
            this.rating = this.ticket.satisfaction_rating.score.toLowerCase();
          }

          if (this.ticket.satisfaction_rating.comment) {
            this.comment = this.ticket.satisfaction_rating.comment;
          }
        }
      }
    },
  },
};
