<template>
  <span class="font-medium text-xs text-grey"> {{ user.name || '-' }} </span>
</template>

<script>
export default {
  name: 'SupportUserDetail',

  props: {
    author: {
      type: Object,
      default() {
        return { name: '' };
      },
    },
  },

  data() {
    return {
      user: {},
    };
  },

  created() {
    this.user = this.author;
  },
};
</script>
