<template>
  <div class="mb-20">
    <!-- <script
      id="ze-snippet"
      type="application/javascript"
      src="https://static.zdassets.com/ekr/snippet.js?key=8d7301b1-3f01-46e7-98ae-36fcb8c33098"
    /> -->
    <div class="w-3/5">
      <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
        {{ $t('support.main.support_overview.title') }}
      </h1>
      <p class="text-sm text-grey-dark block  mb-5">
        {{ $t('support.main.support_overview.desc') }}
      </p>
      <el-alert
        class="warning-alert"
        :title="$t('support.main.recommended_articles')"
        type="warning"
        :closable="false"
        show-icon
      />
      <div class="mt-10">
        <a
          class="relative rounded-lg no-underline block shadow bg-white border border-grey-lightest hover:shadow-md col-card overflow-hidden"
          href="/support/articles"
        >
          <i class="absolute article-icon bg-icon material-icons-outlined block text-grey-lightest">article</i>
          <div class="flex flex-row absolute w-full h-full z-10 p-5">
            <div class="flex items-center justify-center mr-5 w-1/5 text-center">
              <div class="rounded-full h-12 w-12 flex items-center justify-center bg-blue">
                <span class="material-icons-outlined text-white">
                  article
                </span>
              </div>
            </div>
            <div class="w-4/5">
              <h3
                class="mb-3 text-sm font-semibold text-lg text-grey-darker"
              >
                {{ $t('support.main.support_articles.title') }}
              </h3>
              <p class="text-sm text-grey-dark"> {{ $t('support.main.support_articles.desc') }} </p>
            </div>
          </div>
        </a>
        <div class="mt-5 flex">
          <a
            class="row-card flex-1 relative overflow-hidden mr-4 bg-white rounded-lg border border-grey-lightest no-underline block shadow hover:shadow-md"
            href="/support/tickets/create"
          >
            <i class="absolute row-icon bg-icon material-icons-outlined text-grey-lightest">confirmation_number</i>
            <div class="flex flex-col items-center justify-center absolute w-full h-full z-10 p-5">
              <div class="h-12 text-center mb-8">
                <div class="rounded-full h-12 w-12 flex items-center justify-center bg-blue">
                  <span class="material-icons-outlined text-white">
                    confirmation_number
                  </span>
                </div>
              </div>
              <div class="h-24 text-center">
                <h3 class="mb-3 text-sm font-semibold text-lg text-grey-darker">
                  {{ $t('support.main.raise_ticket.title') }}
                </h3>
                <p class="text-sm text-grey-dark">{{ $t('support.main.raise_ticket.desc') }}</p>
              </div>
            </div>
          </a>
          <a
            class="row-card flex-1 relative overflow-hidden mr-4 bg-white border border-grey-lightest rounded-lg no-underline block shadow hover:shadow-md"
            href="/support/tickets"
          >
            <i class="absolute row-icon bg-icon material-icons-outlined text-grey-lightest">history</i>
            <div class="flex flex-col items-center justify-center justify-center absolute w-full h-full z-10 p-5">
              <div class="h-12 text-center mb-8">
                <div class="rounded-full h-12 w-12 flex items-center justify-center bg-blue">
                  <span class="material-icons-outlined text-white">
                    history
                  </span>
                </div>
              </div>
              <div class="h-24 text-center">
                <h3 class="mb-3 text-sm font-semibold text-lg text-grey-darker">
                  {{ $t('support.main.ticket_history.title') }}
                </h3>
                <p class="text-sm text-grey-dark">{{ $t('support.main.ticket_history.desc') }}</p>
              </div>
            </div>
          </a>
          <a
            v-if="isShowChat"
            class="row-card flex-1 overflow-hidden relative bg-white border border-grey-lightest rounded-lg no-underline block shadow hover:shadow-md"
            href="/"
            @click.prevent="toggleLiveChat"
          >
            <i class="absolute row-icon bg-icon material-icons-outlined text-grey-lightest">3p</i>
            <div class="flex flex-col items-center justify-center absolute w-full h-full z-10 p-5">
              <div class="h-12 text-center mb-8">
                <div class="rounded-full h-12 w-12 flex items-center justify-center bg-blue">
                  <span class="material-icons-outlined text-white">
                    3p
                  </span>
                </div>
              </div>
              <div class="h-32 text-center">
                <h3 class="mb-3 text-sm font-semibold text-lg text-grey-darker">
                  {{ $t('support.main.chat.title') }}
                </h3>
                <p class="text-sm text-grey-dark">{{ $t('support.main.chat.desc') }}.</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- <button
      v-if="isChatWindowHide && isScriptLoaded"
      id="custom-launcher"
      class="custom-launcher"
      @click="toggleLiveChat"
    >
      <span class="material-icons-outlined text-white">chat</span>
      <span>{{ $t('support.main.chat.title') }} </span>
    </button> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { handlePackageLimit } from '../../common/handle-package-limit';

export default {
  name: 'SupportOverview',

  components: {

  },

  data() {
    return {
      isScriptLoaded: false,
      isChatWindowHide: true,
      isShowChat: false,
    };
  },

  computed: {
    ...mapGetters({
      chatAuth: 'chat/getChatAuth',
      user: 'user/getUser',
      account: 'user/getAccount',
    }),
  },

  created() {

  },

  mounted() {
    if (handlePackageLimit('cp-support')) this.authenticate();
  },

  beforeDestroy() {
    if (window.zE) {
      window.zE('webWidget', 'close');
      window.zE('webWidget', 'hide');
    }
    this.isShowChat = false;
  },

  methods: {
    ...mapActions({
      getAuth: 'chat/authenticate',
      getAccount: 'user/getAccount',
    }),
    attachScript() {
      return new Promise((resolve) => {
        const script = document.createElement('script');
        script.setAttribute('src', 'https://static.zdassets.com/ekr/snippet.js?key=8d7301b1-3f01-46e7-98ae-36fcb8c33098');
        script.setAttribute('id', 'ze-snippet');
        script.async = true;

        script.onload = () => {
          this.isScriptLoaded = true;
          resolve();
        };

        document.head.appendChild(script);
      });
    },

    async loadScript() {
      try {
        await this.attachScript();
      } catch (e) {
        this.$showError(this, e);
      }
    },

    async authenticate() {
      try {
        await this.getAuth();
        await this.getAccount({
          uid: this.user.UserId,
          aid: this.user.AccountUid,
        });

        const { CustomerCategory: customerCategory } = this.account;

        if (customerCategory && customerCategory === 'VIP') {
          this.isShowChat = true;
          // set first the config
          this.setChatConfig();

          // load the script and will look up to config
          this.loadScript();

          setTimeout(() => {
            this.scripLoaded = true;
            // this.hideOriginalButton();
            this.listenToEvents();
          }, 500);
        }
      } catch (e) {
        this.$showError(this, e);
      }
    },

    setChatConfig() {
      const { token } = this.chatAuth;

      window.zESettings = {
        webWidget: {
          // color: {
          //   theme: '#CB2232',
          //   launcher: '#CB2232', // This will also update the badge
          //   launcherText: '#FFFFFF',
          //   button: '#CB2232',
          // },

          answerBot: {
            suppress: false,
          },

          contactForm: {
            ticketForms: [
              {
                id: 1900000236028,
                fields: [
                  {
                    id: 'description',
                    prefill: {
                      '*': '-',
                    },
                  },

                  {
                    id: 'email',
                    prefill: {
                      '*': this.user ? this.user.Email : '',
                    },
                  },

                  {
                    id: 'subject',
                    prefill: {
                      '*': `8x8 Connect Form (${this.user ? this.user.AccountId : ''})`,
                    },
                  },

                  {
                    id: 900011192783,
                    prefill: {
                      '*': this.user ? this.user.AccountId : '',
                    },
                  },
                ],
              },
            ],
          },

          chat: {
            suppress: false,
            prechatForm: {
              greeting: {
                '*': 'Please fill out the form below to chat with us',
              },
            },

            offlineForm: {
              greeting: {
                '*': 'Sorry, we aren\'t online right now, please complete this form and we will get back to you',
              },
            },

            departments: {
              enabled: [],
            },
          },

          authenticate: {
            chat: {
              jwtFn(callback) {
                callback(token);
              },
            },
          },

          // launcher: {
          //   suppress: false,
          //   chatLabel: {
          //     '*': 'Chat now',
          //   },
          // },

          helpCenter: {
            suppress: false,
            chatButton: {
              '*': 'Chat',
            },
          },

          contactOptions: {
            enabled: true,
            chatLabelOnline: { '*': 'Chat' },
            chatLabelOffline: { '*': 'Chat is unavailable' },
          },
        },
      };
    },

    toggleLiveChat() {
      if (window.zE) {
        if (this.isChatWindowHide) {
          window.zE('webWidget', 'show');
          window.zE('webWidget', 'open');
        } else {
          window.zE('webWidget', 'close');
        }
      }
    },

    hideOriginalButton() {
      if (window.zE) {
        window.zE('webWidget', 'hide');
      }
    },

    listenToEvents() {
      if (window.zE) {
        window.zE(() => {
          let sl = localStorage.getItem('SELECTED_LOCALE');

          if (sl === 'en' || !sl) {
            sl = 'en-us';
          }

          window.zE.setLocale(sl.toLowerCase());
        });

        window.zE('webWidget:on', 'close', () => {
          // window.zE('webWidget', 'hide');
          this.isChatWindowHide = true;
        });

        window.zE('webWidget:on', 'open', () => {
          this.isChatWindowHide = false;
        });
      }
    },
  },
};
</script>
<style lang="scss">
  .warning-alert {
    .el-alert__content .el-alert__title {
      font-size: 15px;
    }
  }

  .support-icons {
    font-size: 32px;
  }

  button.custom-launcher {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 999999999999999999999999999999999999999;
    height: 45px;
    width: 100px;
    background-color: #CB2232;
    color: white;
    border-radius: 5rem;
    padding: 10px;
    text-align: center;
    display: block;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border: none;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);


    span {
      vertical-align: middle;
    }
  }

  button.custom-launcher:focus {
    outline: none !important;
  }

  button.custom-launcher:hover {
    transform: scale(1.1, 1.1);
  }

  .bg-icon {
    z-index: 0;
    user-select: none;
  }

  .article-icon {
    font-size: 300px;
    left: -49px;
    top: -51px;
  }

  .col-card {
    height: 7rem;
  }

  .row-card {
    height: 15rem;
  }

  .row-icon {
    font-size: 255px;
    right: -64px;
    top: -55px;
  }
</style>
