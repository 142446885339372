import moment from 'moment';
import momentDuration from 'moment-duration-format';

momentDuration(moment);

export default {
  methods: {
    // Time difference between current time and given time
    timeFromNow(datetime, utcOffset = 0) {
      return moment.utc(datetime).utcOffset(utcOffset, false).fromNow();
    },

    // Format date
    formatDate(datetime, option) {
      const defaultOption = {
        format: 'D MMM YYYY',
        showCurrYear: true,
        utcOffset: 0,
      };
      option = { ...defaultOption, ...option }; // eslint-disable-line
      option.format = !option.showCurrYear && moment(datetime).format('YYYY') === moment().format('YYYY') // eslint-disable-line
        ? option.format.replace(/[yY]/g, '').trim()
        : option.format;

      return moment.utc(datetime).utcOffset(option.utcOffset, false).format(option.format);
    },

    // Relative to Calendar
    timeFromCalendar(datetime) {
      return moment(datetime).calendar(null, {
        sameDay: '[Today], hh:mm A',
        lastDay: '[Yesterday], hh:mm A',
        nextDay: '[Tomorrow], hh:mm A',
        lastWeek: '[Last] ddd, hh:mm A',
        nextWeek: 'ddd, hh:mm A',
        sameElse() {
          return this.year() === moment().year() ? 'D MMM, hh:mm A' : 'D MMM YYYY, hh:mm A';
        },
      });
    },

    getDateHeader(tz, text) {
      return `${text || this.$t('column_labels.date')} ${tz}`;
    },

    isCurrentDate(t, utcOffset = 0) {
      return moment.utc(t).utcOffset(utcOffset, false).isSame(new Date(), 'day');
    },

    isYesterday(t, utcOffset = 0) {
      const y = moment()
        .subtract(1, 'days')
        .startOf('day');

      return moment.utc(t).utcOffset(utcOffset, false).isSame(y, 'day');
    },

    friendlyTimestamp(t, utcOffset = 0) {
      if (this.isYesterday(t, utcOffset)) {
        return `Yesterday, ${moment.utc(t).utcOffset(utcOffset, false).format('hh:mm A')}`;
      }

      if (this.isCurrentDate(t, utcOffset)) {
        return `Today, ${moment.utc(t).utcOffset(utcOffset, false).format('hh:mm A')}`;
      }

      return moment.utc(t).utcOffset(utcOffset, false).format('MMM DD, YYYY hh:mm A');
    },

    dateTimestamp(t, utcOffset = 0) {
      return moment.utc(t).utcOffset(utcOffset, false).format('MMM DD, YYYY');
    },
  },
};
