import http from '../../../../utils/http';

export default {
  authenticate: async ({ commit }) => {
    try {
      const response = await http.v2.post('/support/chat/auth');
      const { data } = response;

      commit('SET_CHAT_AUTH', data);

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
