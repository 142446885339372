<template>
  <div
    v-loading="refreshing"
    class="relative"
  >
    <div
      ref="chatWindow"
      class="chat-window mt-2 px-3 py-1 overflow-y-auto overflow-x-hidden border border-grey-light rounded text-xs"
    >
      <div v-if="request.comments && request.comments.length">
        <div
          v-for="(v, i) in request.comments"
          :key="i"
        >
          <div v-if="!chat(i, request).isSystemGenerated">
            <div
              v-if="chat(i, request).startsSequence"
              class="separator text-grey-light text-xs mb-5"
            >
              {{ friendlyTimestamp(v.created_at, getTz.timeZoneOffset) }}
            </div>
            <div
              class="flex chat-bubble-parent"
              :class="{ 'flex-row-reverse': chat(i, request).isMine, 'flex-row': !chat(i, request).isMine }"
            >
              <div
                class="p-3 mb-3 rounded-lg chat-bubble break-words"
                :class="{ 'bg-blue-lightest': chat(i, request).isMine, 'bg-grey-lightest': !chat(i, request).isMine}"
              >
                <div class="flex  mb-1">
                  <div class="flex-1">
                    <svg
                      v-if="!chat(i, request).isMine"
                      class="logo text-blue"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 302 139"
                      @click="navigate({ path: '/'})"
                    >
                      <path
                        d="M194.051 51.161h-32.563l-7.505 14.288c-1.022 2.213-2.725 4.712-2.725 4.712h-.341s-1.532-2.499-2.724-4.712l-7.506-14.288h-32.546l25.578 39.482-25.473 39.518h31.995l8.633-16.498c.852-1.532 2.043-4.502 2.043-4.502h.341s1.192 2.97 2.043 4.502l8.775 16.498h32.023l-25.472-39.518 25.424-39.482M49.807 110.055c-9.022 0-16.164-6.954-16.164-15.599 0-7.331 3.947-12.969 7.142-16.352 13.157 6.014 25.186 10.525 25.186 18.231 0 8.834-6.202 13.72-16.164 13.72zm.94-81.007c8.834 0 14.096 4.887 14.096 12.405 0 8.27-3.571 14.473-4.699 16.54-11.84-5.075-21.614-9.585-21.614-18.419 0-5.827 3.759-10.526 12.217-10.526zm33.079 40.598c1.316-1.692 13.157-16.164 13.157-30.636C96.983 14.012 76.496.104 51.499.104 20.111.104 6.39 19.275 6.39 38.822c0 11.465 5.075 19.547 12.217 25.749C14.472 67.766 0 79.983 0 97.275 0 118.701 17.104 139 49.807 139c33.267 0 49.807-20.487 49.807-40.973 0-13.533-6.766-22.179-15.788-28.381zM252.079 110.055c-9.021 0-16.163-6.954-16.163-15.599 0-7.331 3.947-12.969 7.142-16.352 13.156 6.014 25.185 10.525 25.185 18.231 0 8.834-6.202 13.72-16.164 13.72zm.94-81.007c8.834 0 14.097 4.887 14.097 12.405 0 8.27-3.572 14.473-4.7 16.54-11.84-5.075-21.614-9.585-21.614-18.419 0-5.827 3.759-10.526 12.217-10.526zm33.08 40.598c1.315-1.692 13.156-16.164 13.156-30.636 0-24.998-20.487-38.906-45.484-38.906-31.388 0-45.108 19.171-45.108 38.718 0 11.465 5.074 19.547 12.217 25.749-4.135 3.195-18.608 15.412-18.608 32.704 0 21.426 17.104 41.725 49.807 41.725 33.268 0 49.807-20.487 49.807-40.973 0-13.533-6.766-22.179-15.787-28.381z"
                        class="logo__path"
                        fill="currentColor"
                        clip-rule="evenodd"
                        fill-rule="evenodd"
                        stroke="none"
                      />
                    </svg>
                  </div>
                  <div
                    class="text-grey-dark text-right flex-1"
                    :style="{fontSize: '10px'}"
                  >
                    {{ formatDate(v.created_at, { utcOffset:getTz.timeZoneOffset, format: 'hh:mm A' }) }}
                  </div>
                </div>
                <div v-html="sanitizeHtml(getBody(v.html_body, v.plain_body, i))" />
                <div
                  v-if="getAttachments(v.attachments).length"
                  class="mt-5 flex flex-row-reverse"
                >
                  <div
                    v-for="(x, idx) in getAttachments(v.attachments)"
                    :key="idx"
                    class="ml-2 w-16"
                  >
                    <file-thumbnail :file="x" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="text-center mt-20 text-grey text-xs"
      >
        {{ $t('support.chat.convo_empty') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import FileThumbnail from './FileThumbnail.vue';

import timeFormatter from '../../mixins/timeFormatter';
import utils from '../../utils';

export default {
  name: 'SupportChatHistory',

  components: {
    FileThumbnail,
  },

  mixins: [timeFormatter],

  props: {
    request: {
      type: Object,
      default: () => ({}),
    },

    refreshing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {

    };
  },

  computed: {
    ...mapGetters({
      getTz: 'user/getTz',
    }),
  },

  watch: {
    'request.comments': {
      handler(current, old) {
        const notSameLength = old && Array.isArray(old) && current && Array.isArray(current) && old.length !== current.length;
        const hasCurentOnly = !old && current && Array.isArray(current) && current.length;
        if (notSameLength || hasCurentOnly) {
          this.$nextTick(() => {
            const container = this.$refs.chatWindow;
            container.scrollTop = container.scrollHeight + 350;
          });
        }
      },

      deep: true,
    },
  },

  methods: {
    chat(i, request) {
      const utcOffset = this.getTz.timeZoneOffset;
      const previous = request.comments[i - 1];
      const current = request.comments[i];
      const next = request.comments[i + 1];
      // const isRequester = current.author_id === request.requester_id;
      const isMine = request.comments[i].via
        && request.comments[i].via.source
        && request.comments[i].via.source.to
        && request.comments[i].via.source.to.address
        && request.comments[i].via.source.to.address.match(/@8x8.com|@wavecell.zendesk.com/);

      const isSystemGenerated = request.comments[i].metadata
        && request.comments[i].metadata.system
        && request.comments[i].metadata.system.client.match(/Zendesk URL Target/);

      const currentMoment = moment.utc(current.created_at).utcOffset(utcOffset, false);

      let prevBySameUser = false;
      let nextBySameUser = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;
      let showMsgTime = true;

      if (previous) {
        const previousMoment = moment.utc(previous.created_at).utcOffset(utcOffset, false);
        const previousDuration = moment.duration(
          currentMoment.diff(previousMoment),
        );
        prevBySameUser = previous.author_id === current.author_id;
        if (prevBySameUser && previousDuration.as('hours') < 1) {
          startsSequence = false;
        }
        if (previousDuration.as('hours') < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        const nextMoment = moment.utc(next.created_at).utcOffset(utcOffset, false);
        const nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameUser = next.author_id === current.author_id;
        if (nextBySameUser && nextDuration.as('hours') < 1) {
          endsSequence = false;
        }
      }

      if (request.comments.length && request.comments.length - 1 !== i) {
        showMsgTime = false;
      }
      return {
        isMine,
        isSystemGenerated,
        startsSequence,
        endsSequence,
        showTimestamp,
        showMsgTime,
      };
    },

    sanitizeHtml(content) {
      return utils.sanitizeHtml(content);
    },

    getBody(htmlbody, plainbody, index) {
      let comment = utils.getBody(htmlbody, plainbody);

      // Check if it's the first comment in the sender id ticket
      // Contains the copy of the form
      if (this.request.isSenderId && index === 0) {
        const titleIndex = comment.indexOf('</p>');
        comment = `${comment.slice(0, titleIndex + 4)}<p></p><p></p>${comment.slice(titleIndex + 4)}`;
        return comment.replace(/\n/g, '');
      }

      return comment.replace(/\n/g, '<p></p>');
    },

    getAttachments(attachments) {
      return attachments.filter(v => !v.deleted);
    },
  },
};
</script>
<style lang="scss">
  .chat-window {
    height: calc(100vh - 250px);
    min-height: 400px;
    width: 100%;

    .logo {
      width: 20px;
      height: 10px;
    }

    .separator {
      display: flex;
      align-items: center;
      text-align: center;
    }

    .separator::before,
    .separator::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid #dae1e7;
    }

    .separator:not(:empty)::before {
      margin-right: .25em;
    }

    .separator:not(:empty)::after {
      margin-left: .25em;
    }

    .chat-bubble {
      ul,
      ol {
        padding: 0 1rem;
      }

      blockquote {
        padding-left: 1rem;
        border-left: 3px solid rgba(#0D0D0D, 0.1);
      }

      a {
        color: #f33;
      }

      code {
        font-size: 12px;
        padding: 0.25em;
        border-radius: 0.25em;
        background-color: #F8F9F9;
        border-color: #C2C8CC;
        color: #616161;
        box-decoration-break: clone;
      }

      pre {
        background: #F8F9F9;
        color: #616161;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;
        border-color: #C2C8CC;

        code {
          color: inherit;
          padding: 0;
          background: none;
          font-size: 0.8rem;
        }
      }

      hr {
        border-top: 1px solid #C2C8CC;
      }

      table, th, td {
        border: 1px solid #616161;
      }

      table td, table th {
        padding: 2px;
        min-width: 3em;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;
      }

      table tr:first-child {
        font-weight: 500;
      }

      p {
        min-height: 1px;
        margin-bottom: 5px;
      }
    }

    .chat-bubble {
      max-width: 80%;
      // white-space: pre-wrap;
    }
  }
</style>
